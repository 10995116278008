import styled from "styled-components"

export const Form = styled.form`
  padding: 20px 20px 20px 0;
  display: grid;

  @media screen and (max-width: 768px) {
    padding: 20px 0px;
    // max-width: 200px;
  }
`

export const FormH3 = styled.h3`
  font-size: 1.2rem;
  margin: 1rem 0;
  font-weight: bold;
`

export const FormEmail = styled.div`
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 500;
  height: 60px;
  width: 100%;
  @media screen and (max-width: 440px) {
    // width: 260px;
  }
  @media screen and (max-width: 300px) {
    width: 200px;
  }
`
export const FormName = styled.div`
  font-size: 1.2rem;
  margin-bottom: 16px;
  font-weight: 500;
  width: 48%;
  height: 60px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 300px) {
    width: 200px;
  }
`
export const FormError = styled.div`
  font-size: 0.8rem;
  color: red;
  margin-left: 20px;
  margin-top: 4px;
`
export const DoubleFormSelectWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media screen and (max-width: 768px) {
    // width: 100%;
    display: flow-root;
    gap: 0;
  }
`

export const FormInputWrap = styled.div`
  width: 100%;

  @media screen and (max-width: 300px) {
    width: 200px;
  }
`
export const FormInputWrapComment = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
  @media screen and (max-width: 300px) {
    width: 200px;
  }
`

export const FormInput = styled.input`
  height: 46px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid grey;

  padding: 0 1rem;
  font-size: 0.9rem;
  ::placeholder {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 300px) {
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const FormSelect = styled.select`
  height: 46px;
  padding-left: 10px;
  margin-bottom: 26px;

  border-radius: 6px;
  border: 1px solid grey;

  width: 100%;

  @media screen and (max-width: 300px) {
    width: 200px;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const FormBtnWrap = styled.div`
  // margin: 1rem 0;

  // @media screen and (max-width: 768px) {
  //   width: 100%;
  // }
`
export const FormSendBtn = styled.button`
  background: white;
  // background: black;
  padding: 1rem 2rem;
  color: black;
  // color: white;
  border: none;
  cursor: pointer;
  width: 47%;
  margin-top: 1rem;

  border-radius: 6px;
  font-size: 1.1rem;

  &:hover {
    background-color: #b9acac;
  }

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    padding: 1rem 2rem;
    width: 100%;
  }
`
