import styled from "styled-components"
import { Carousel } from "react-responsive-carousel"
import { Link as LinkRouter } from "react-router-dom"

export const ItemComponentContainer = styled.div``

export const ItemComponentWrapper = styled.div`
  @media screen and (max-width: 1000px) {
    // padding: 0px 60px;
    display: grid;
    justify-content: center;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0px 40px;

  @media screen and (max-width: 768px) {
    padding: 0px 0px;
    display: grid;
    justify-content: center;
  }
`
export const ItemBreadCrumbs = styled.div`
  display: flex;

  margin-bottom: 40px;
`
export const ItemBreadCrumbsLink = styled(LinkRouter)`
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
  margin-bottom: auto;
  margin-top: auto;

  @media screen and (max-width: 768px) {
    font-size: 0.6rem;
    margin-left: 1rem;
  }
`
export const ItemCarouselWrap = styled.div`
  width: 35%;
  @media screen and (max-width: 1000px) {
    width: 45%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;

    display: grid;
    justify-content: center;
  }
`

export const ItemCarousel = styled(Carousel)`
  .carousel .carousel-status {
    display: none;
  }
  // height: auto;
`
export const ItemCarouselImgWrap = styled.div`
  @media screen and (max-width: 768px) {
    // height: 10rem;
    // width: 100%;

    img {
      width: 100%;
      vertical-align: top;
      border: 0;
    }
  }


`

export const ItemInfo = styled.div`
  width: 45%;
  @media screen and (max-width: 1200px) {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ItemInfoH1 = styled.h1`
  text-align: center;
  font-size: 1.2rem;
  // padding-bottom: 1rem;
  // border: 1px dashed #7c7979;
  border-left: none;
  border-right: none;
  border-top: none;

  // background: white;
  padding: 1rem;
  color: white;

  // border-radius: 16px;
`
export const ItemPrice = styled.h2`
  text-align: center;
  font-size: 1.1rem;
  // font-weight: bold;
  color: white;
  margin: 0.6rem 0;
`
export const ItemShortDescriptionWrap = styled.div`
  // margin: 20px 0px;
`
export const ItemCharacterisctics1 = styled.div`
  // border: 1px dashed #7c7979;
  // border: 1px solid black;
  // background: white;
  padding: 1rem 0;
  // border-radius: 16px;

  display: flex;
  justify-content: center;

  // border-left: none;
  // border-right: none;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 1rem 1rem;
  }

  ul {
    width: fit-content;
    font-size: 1.1rem;
    li:not(:last-child) {
      margin-bottom: 0.2rem;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
    }
  }
`
export const ItemCharacterisctics2 = styled.div`
  // border: 1px dashed #7c7979;
  // border: 1px solid black;
  // background: white;
  padding: 1rem 2rem;
  // border-radius: 16px;

  display: flex;
  justify-content: center;

  // margin: 0rem 0rem 1rem 1rem;
  margin-bottom: 1rem;

  // border-left: none;
  // border-right: none;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 1rem 1rem;
  }

  ul {
    width: fit-content;

    font-size: 1.1rem;
    li:not(:last-child) {
      margin-bottom: 0.26rem;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
    }
  }
`

export const ItemShortDescription = styled.div`
  padding: 20px 0px;
  border: 1px dashed #7c7979;
  border-left: none;
  border-right: none;
  border-top: none;

  white-space: pre-wrap;
`

export const ItemInfoP = styled.p`
  // text-align: center;
  text-align: justify;
  font-size: 1rem;

  @media screen and (max-width: 768px) {
    text-align: left;
  }
`
export const ItemAddWrap = styled.div`
  display: grid;
`

export const ItemCount = styled.div`
  background: white;
  width: 100px;
  height: 40px;
  color: black;
  border: 1px solid black;
  display: flex;
  justify-content: space-around;
  padding: 6px 0px;

  margin: 1rem auto;

  border-radius: 7px;
`
export const ItemAddBtn = styled(LinkRouter)`
  background: white;
  width: 220px;
  height: 40px;

  border: 1px solid black;
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  text-decoration: none;
  color: black;

  border-radius: 7px;

  font-size: 1rem;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`
export const ItemDescriprionWrap = styled.div`
  border-top: 1px #a09e9e solid;
  max-width: 660px;
`
export const AboutLink = styled(LinkRouter)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 16px;

  &.active {
    border-bottom: 3px solid #01bf71;
  }
`

export const AMLinkWrap = styled.div`
  background: white;
  margin-top: 1rem;
  width: 220px;
  height: 40px;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: space-around;
  border-radius: 6px;
  // padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgb(0 0 0 / 40%);

  &:hover {
    box-shadow: 0 4px 6px rgb(0 0 0 / 40%);
  }
`
export const AMLink = styled.a`
  gap: 1rem;

  text-decoration: none;
  p {
    color: black;
    font-size: 1rem;
    font-weight: bold;
    margin: auto 0;

    @media screen and (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`
export const ItemDownloadButtons = styled.div`
  display: grid;
  gap: 1rem;
  margin: 0 1rem;
  // padding: 0 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 768px) {
    margin: 2rem 0;
    // margin-bottom: 2rem;
    padding: 0 8rem;
  }

  @media screen and (max-width: 600px) {
    margin: 2rem 0;
    // margin-bottom: 2rem;
    padding: 0 4rem;
  }

  @media screen and (max-width: 400px) {
    margin: 2rem 0;
    // margin-bottom: 2rem;
    padding: 0 0.8rem;

  }
`
