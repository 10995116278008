import styled from "styled-components"

export const ItemPageContainer = styled.div`
  // padding: 50px 120px;
  padding: 0px 80px;

  @media screen and (max-width: 1200px) {
    padding: 0px 60px;
  }
  
  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }

  background: black;
  color: white;
`

export const ItemPageWrapper = styled.div`
  // padding: 50px 120px;
  // height: 1200px;
  // background: #d0d3d6;
  padding: 40px 0;

`