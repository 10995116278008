import { Helmet } from "react-helmet-async"

const SEO = ({description, title, keywords, canonical}) => {
  return (
    <Helmet>
      <meta
        name="description"
        content={description}
      />
      <title>
        {title}
      </title>
      <meta
        name="keywords"
        content={keywords}
      />
       <link rel="canonical" href={canonical}/>
    </Helmet>
  )
}

export default SEO
