import { useContext, useEffect, useState } from "react"
import { MenuContext } from "../../context/menuContext"
import { ShopContext } from "../../context/shopContext"
import {
  BtnCheckOut,
  BtnClear,
  BtnWrap,
  CartContainer,
  CartWrapper,
  ItemsCountWrap,
  ListWrap,
  Table,
  TableHead,
  TableRow,
  TableWrap,
  TdNameLink,
  TdNameLinkWrap,
  TotalH1,
} from "./CartElements"
import DeleteItem from "./DeleteItem"
import CountItem from "./CountItem"
import CartIsEmpty from "./CartIsEmpty"
import { total_price } from "../../helpers"
import DeliveryComponent from "./DeliveryComponent"
import PageHeadWrapper from "../../components/Elements/PageHeadWrapper"
import {
  BreadCrumbs,
  BreadCrumbsLink,
  BreadCrumbsP,
} from "../../components/Elements/StyledElements"
import { animateScroll as scroll } from "react-scroll/modules"
import SEO from "../../components/SEO"

const Cart = () => {
  const { order, clearBasket, removeFromBasket, incQuantity, decQuantity } =
    useContext(ShopContext)
  const { togglePages } = useContext(MenuContext)
  const [isCheckOut, setIsCheckOut] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)
  const handleCheckOutBtn = () => {
    try {
      window.gtag('event', 'clickBtnCheckOut', {'event_name': 'click'});
     } 
      catch (error) { console.log(error)}
    setIsCheckOut(true)
  }
  useEffect(() => {
    togglePages()
    scroll.scrollToTop()

    // setIsCheckOut(false)
  }, [])
  useEffect(() => {
    let total = 0
    order.map((item, index) => {
      total = total + Number(total_price(item.current_price, item.quantity))
    })
    setTotalPrice(total)
  }, [order])
  // useEffect(()=>{
  //   window.localStorage.setItem('cart', JSON.stringify(order));

  // },[])
  return (
    <>
     <SEO
        description="Armreels | Cart"
        title="Armreels | Cart"
        keywords="centerpin fishing armreels cart"
      />
    
    <CartContainer className="CartContainer">
      <CartWrapper className="CartWrapper">
        <BreadCrumbs>
          <BreadCrumbsLink to="/">HOME</BreadCrumbsLink>
          <BreadCrumbsP>›</BreadCrumbsP>
          <BreadCrumbsLink
            to="/cart"
            onClick={() => {
              setIsCheckOut(false)
            }}
          >
            CART
          </BreadCrumbsLink>
          {isCheckOut && (
            <>
              <BreadCrumbsP>›</BreadCrumbsP>
              <BreadCrumbsLink style={{ cursor: "default" }}>
                DELIVERY
              </BreadCrumbsLink>
            </>
          )}
        </BreadCrumbs>
        {/* <H1Wrap className="H1Wrap">
          <h1>Cart</h1>
        </H1Wrap> */}

        {order.length !== undefined && order.length !== 0 ? (
          <>
            {!isCheckOut ? (
              <>
                <PageHeadWrapper text="Cart" />

                <ListWrap>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <ItemsCountWrap>
                      Items selected: {order.length}
                    </ItemsCountWrap>
                    <BtnClear onClick={clearBasket}><p>CLEAR CART</p></BtnClear>
                  </div>
                  <TableWrap className="TableWrap">
                    <Table>
                      <thead>
                        <TableRow>
                          <TableHead width={100}></TableHead>
                          <TableHead width={600} style={{ minWidth: "200" }}>
                            Item
                          </TableHead>
                          <TableHead width={140} style={{ minWidth: "200" }}>
                            Type
                          </TableHead>
                          <TableHead width={140}>Count</TableHead>
                          <TableHead width={200}>Price</TableHead>
                          <TableHead width={200}>Total</TableHead>
                          <TableHead width={100}></TableHead>
                        </TableRow>
                      </thead>
                      <tbody>
                        {order.map((item, index) => {
                          if (item.quantity > 0)
                            return (
                              <TableRow
                                key={item.vendor_code + item.clockwise_type}
                              >
                                <td style={{ padding: "20px 10px" }}>
                                  {index + 1}
                                </td>
                                <TdNameLinkWrap>
                                  <TdNameLink to={`/shop/${item.name}`}>
                                    {item.fullname}
                                  </TdNameLink>
                                </TdNameLinkWrap>
                                <td style={{ padding: "20px 0px" }}>
                                  {item.clockwise_type}
                                </td>
                                <td>
                                  <CountItem
                                    quantity={item.quantity}
                                    itemId={item.vendor_code}
                                    decQuantity={decQuantity}
                                    incQuantity={incQuantity}
                                    clockwise_type={item.clockwise_type}
                                  />
                                </td>
                                <td>{item.current_price}</td>
                                <td>
                                  $
                                  {total_price(
                                    item.current_price,
                                    item.quantity
                                  )}
                                </td>
                                <td>
                                  <DeleteItem
                                    removeFromBasket={removeFromBasket}
                                    itemId={item.vendor_code}
                                    clockwise_type={item.clockwise_type}
                                  />
                                </td>
                              </TableRow>
                            )
                          else return ""
                        })}
                      </tbody>
                    </Table>
                  </TableWrap>
                  <BtnWrap>
                    <BtnCheckOut onClick={handleCheckOutBtn}>
                      <p>CHECK OUT</p>
                      
                    </BtnCheckOut>
                    <TotalH1>Total: ${totalPrice}</TotalH1>
                  </BtnWrap>
                </ListWrap>
              </>
            ) : (
              <DeliveryComponent
                order={order}
                total={totalPrice}
                clearBasket={clearBasket}
              />
            )}
          </>
        ) : (
          <CartIsEmpty />
        )}
      </CartWrapper>
    </CartContainer>
    </>
  )
}

export default Cart
