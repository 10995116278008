import styled from "styled-components"
import { Link as LinkRouter } from "react-router-dom"

export const GalleryContainer = styled.div`
  background: #f1f1f1;
  background: black;
  padding: 0px 80px;

  @media screen and (max-width: 1200px) {
    padding: 0px 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }

  height: auto;
`
export const GalleryWrapper = styled.div`
  height: auto;
  padding: 40px 0;
`
export const GalleryBreadCrumbs = styled.div`
  display: flex;
  margin-bottom: 40px;
`
export const GalleryBreadCrumbsLink = styled(LinkRouter)`
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
  margin-bottom: auto;
  margin-top: auto;
`
export const AphorismContainer = styled.div`
  padding: 20px 60px;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  color: white;

  @media screen and (max-width: 1200px) {
    padding: 20px 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px 20px;
  }
`
export const AphorismWrap = styled.div`
  border: 1px solid white;
  padding: 20px 10px;
  border-left: none;
  border-right: none;
  width: 640px;
`
export const GalleryPrimaryListWrapper = styled.div`
  margin: 0 auto;
  columns: 3;
  column-gap: 2rem;
  row-gap: 2rem;

  @media screen and (max-width: 1200px) {
    columns: 2;
    // column-gap: 2rem;
    // row-gap: 2rem;
  }
  @media screen and (max-width: 768px) {
    columns: 1;
    // column-gap: 2rem;
    // row-gap: 2rem;
  }
`
export const GalleryListWrapper = styled.div`
  margin: 0 auto;
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  //   gap: 4rem;
  //   align-items: center;
  columns: 3;
  column-gap: 1.4rem;

  @media screen and (max-width: 1200px) {
    columns: 2;
    column-gap: 1.6rem;
  }
  @media screen and (max-width: 768px) {
    columns: 1;
    column-gap: 1.6rem;
  }
`
export const ImgWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 480px) {
    // padding: 16px;
  }
  img {
    // width: 100%;
    margin-bottom: 1rem;

    width: 320px;
    height: auto;
    // padding: 1rem 0rem;
  
    @media screen and (max-width: 768px) {
      width: 360px;
      // padding: 1rem 2rem 0rem 2rem;
    }
  
    @media screen and (max-width: 480px) {
      width: 260px;
      // padding: 1rem 0;
    }
  
    @media screen and (max-width: 320px) {
      width: 220px;
      // padding: 1rem 0;
    }
  }
`
