import { useEffect, useState } from "react"
import { CartIsEmptyBtn } from "./CartIsEmptyElements"
import img1 from "../../../images/emptycart3.png"
import PageHeadWrapper from "../../../components/Elements/PageHeadWrapper"

const CartIsEmpty = () => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => window.addEventListener("scroll", changeScroll), [])

  const changeScroll = () => {
    if (window.scrollY > 80) setScroll(true)
    else setScroll(false)
  }

  return (
    <>
      <PageHeadWrapper text="Cart" />

      <div
        style={{
          padding: "20px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          // height: "auto",
          height:"100vh",
        }}
        className="CartIsEmpty wrapper"
      >
        <div>
          <img src={img1} alt="your armreels cart is empty " style={{width: "100%"}}></img>
          <div>
            <h2
              style={{
                marginTop: "40px",
                marginBottom: "20px",
                textAlign: "center",
                fontSize: "1.8rem",
              }}
            >
              Your cart is empty
            </h2>
            <h2
              style={{
                marginBottom: "40px",
                textAlign: "center",
                fontSize: "1.2rem",
              }}
            >
              You have no items in your shopping cart.
            </h2>
            {/* <h2 style={{
              marginBottom: "40px",
              textAlign: "center",
              marginTop: "40px",
            }}>
            You can fill your basket
            <Link to="/shop" style={{ margin: "auto" }}>
              here
            </Link>
          </h2> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CartIsEmptyBtn to="/shop">SHOP NOW</CartIsEmptyBtn>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CartIsEmpty
