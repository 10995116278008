import { ElementContainer, ElementHead, TextBlockWrapper } from "./Elements"
import { BtnToShop, BtnWrap } from "../../AboutCenterpinsElements"
import FirstForm from "../../../../components/HomeHeroSection/FirstForm"

const AboutArmreel25 = () => {
  return (
    <>
      <ElementContainer id="about-Armreel-25">
        <ElementHead>Armreel-25</ElementHead>
        {/* <ElementH2>Why is Armreel 45 better than others?</ElementH2> */}
        <TextBlockWrapper>
          The Armreel-25 series model is currently the smallest in the family of
          our reels and the lightest, while it retains all the best properties —
          easy running, strength, reliability and durability. The spool has a
          rigid stopper of the original design with a soft clicker.
          <br />
          <br />
          Right—hand - winding clockwise.
          <br />
          Left—hand - winding counterclockwise.
          <br />
          <br />
          Armreel-25 is designed for fishing methods that require a compact,
          lightweight but powerful reel with an easily rotating spool. The reel
          is resistant to sand, dirt and water ingress.
          <div style={{ padding: "1rem 1rem" }}>
            <b>Features:</b>
            <ul
            // style={{listStyleType: "none"}}
            >
              <li>Anti-reverse clicker</li>
              <li>Sand-resist</li>
            </ul>
          </div>
          <div style={{ padding: "1rem 1rem" }}>
            <b>SPEC:</b>
            <ul
            // style={{listStyleType: "none"}}
            >
              <li>Mass: 2,6 oz. / 75 gramms</li>
              <li>Spool diam: 2.5 in. / 64 mm</li>
              <li>Spool width: 0.51 in. / 13 mm </li>
              <li>Capacity: 65 yds / 60 m (line 0.35 mm)</li>
              <li>Rotation: 2 x ABEC-5 marine-grade bearings</li>
              <li>Tractive effort: 25 lbs / 10 kg</li>
              <li>
                Warranty: The warranty against manufacturing defects is 5 years.
                Since 2009 of the master's work, only 0.1% of buyers have
                applied for warranty service.
              </li>
            </ul>
          </div>
        </TextBlockWrapper>
        <BtnWrap>
          <BtnToShop to="/shop/Armreel-25">BUY NOW</BtnToShop>
        </BtnWrap>
      </ElementContainer>
      <FirstForm />
    </>
  )
}

export default AboutArmreel25
