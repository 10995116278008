import { IoMdClose } from "react-icons/io"

const DeleteItem = ({ removeFromBasket, itemId, clockwise_type }) => {
  return (
    <IoMdClose
      style={{ cursor: "pointer" }}
      onClick={() => removeFromBasket(itemId, clockwise_type)}
    />
  )
}

export default DeleteItem
