import Element from "./Element"
// import Image1 from "../../../images/home/image1.jpg"
// import Image2 from "../../../images/home/image2.jpg"
// import Image3 from "../../../images/home/image3.jpg"
// import Image4 from "../../../images/home/image4.jpg"

const ThirdBlock = () => {
  return (
    <div>
      <Element
        // image={Image1}
        imageSrc="https://ik.imagekit.io/armreels/tr:w-800/home/image1.jpg?updatedAt=1685386911281"
        // imageSrcSet="https://ik.imagekit.io/armreels/tr:w-400/home/image1.jpg?updatedAt=1685386911281 400w, 
        // https://ik.imagekit.io/armreels/tr:w-800/home/image1.jpg?updatedAt=1685386911281 800w"
        alt="armreels centerpin fishing Jesse"
        name="Jesse"
        country="USA, NY"
        contact="@n8ive_funk_fishing"
        short_desc="Great pin.."
        text="So I got this reel and I am pleasantly shocked how good it is. I
          normally fish a much more expensive conventional style center pin but
          decided to try one of these out. First impressions were really good
          and I liked the craftsmanship of the reel. Had great start up with the
          abec bearings in it. Took it out following day and caught some
          rainbows on it now to get it to the river to get some steelhead! Don't
          sleep on these reels because I have a good feeling they are gonna go
          up in price once people start trying them out."
        model="ARMREEL 45 (Left Hand)"
      />
      <Element
        // image={Image2}
        imageSrc="https://ik.imagekit.io/armreels/home/tr:w-800/image2.jpg?updatedAt=1685386454153"
        // imageSrcSet="https://ik.imagekit.io/armreels/home/tr:w-400/image2.jpg?updatedAt=1685386454153 400w, 
        // https://ik.imagekit.io/armreels/tr:w-800/home/image2.jpg?updatedAt=1685386454153 800w"
        alt="armreels centerpin fishing Tim"
        name="Tim Hughes"
        country="USA, NY"
        contact="@pinning_the_716"
        short_desc="Very lightweight pin"
        text="The Armreel 45 is a very lightweight pin, with an ultra light start up, the abec 7 bearing make it a super smooth reel, the tension knob allows you to dial in your centerpin for minimal backlashes and tangles, the air brake make one handed casting effortless, all around this is a solid reel to have on the tributaries."
        model="ARMREEL 45 (Right Hand)"
      />
      <Element
        // image={Image3}
        imageSrc="https://ik.imagekit.io/armreels/home/tr:w-800/image3.jpg?updatedAt=1685386455846"
        // imageSrcSet="https://ik.imagekit.io/armreels/home/tr:w-400/image3.jpg?updatedAt=1685386455846 400w, 
        // https://ik.imagekit.io/armreels/tr:w-800/home/image3.jpg?updatedAt=1685386455846 800w"
        alt="armreels centerpin fishing Austin"
        name="Austin Curtin"
        country="USA, IA"
        contact="@austincurtin40"
        short_desc="Very clean, well thought out"
        text="At first, I was a bit skeptical of this reel claims of a air brake… Well as I sit here eating my words with a spoon, the air brakes are something you just have to see or cast to appreciate. Centerpin has been around for years, started in Europe. Canada has a massive Centerpin community, and now it’s the fastest growing style of fishing in the Great Lakes region. The Arm reel had an industry only air brake design to stop backlash associated with any kind of free spool reel. Get this reel a try and you won’t be disappointed!"
        model="ARMREEL 45 (Left Hand)"
      />
      <Element
        // image={Image4}
        imageSrc="https://ik.imagekit.io/armreels/home/tr:w-400/image4.jpg?updatedAt=1685386452490"
        // imageSrcSet="https://ik.imagekit.io/armreels/home/tr:w-400/image4.jpg?updatedAt=1685386452490 400w, 
        // https://ik.imagekit.io/armreels/tr:w-800/home/image4.jpg?updatedAt=1685386452490 800w"
        alt="armreels centerpin fishing Lawrence"
        name="Lawrence R McMahon"
        country="USA, MI"
        contact="@mcmahon8851"
        short_desc="Fantastic reel!"
        text="I am new to center pinning, and enjoy this reel very much. It can be used on a bait or spin cast rod. The aerodynamic brake system performs very well and can be treated like a bait caster. When I’m not floating for steelhead and fishing the pond, I can throw the Armreel on my bass rod and cast crank baits with it. In the video shown (I apologize my form isn’t the best) I have the reel on a 7’ MH rod and am casting with one hand while recording with the other. I’m looking forward to bringing in more fish with this fine reel."
        model="ARMREEL 45 (Left Hand)"
      />
    </div>
  )
}

export default ThirdBlock
