import { AboutElementsContainer } from "./AboutItemElements"
import AboutArmreel25 from "./Armreel-25"
import AboutArmreel35 from "./Armreel-35"
import AboutArmreel45 from "./Armreel-45"
import AboutArmreel45E from "./Armreel-45-E"

const AboutItems = () => {
  return (
    <AboutElementsContainer className="AboutElementsContainer">
      <AboutArmreel45/>
      {/* <div style={{border:"1px solid grey", margin: "0px 4rem"}}></div> */}
      <AboutArmreel45E />
      <AboutArmreel35 />
      <AboutArmreel25/>
    </AboutElementsContainer>
  )
}

export default AboutItems
