import styled from "styled-components"
import { Link as LinkRouter } from "react-router-dom"

export const ContactUsContainer = styled.div`
  // height: auto;
  min-height: 100vh;
  background: black;
  // background: #f1f1f1;
  padding: 0px 80px;

  @media screen and (max-width: 1200px) {
    padding: 0px 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`
export const ContactUsWrapper = styled.div`
  height: auto;
  padding: 40px 0;
`
export const ContactUsBreadCrumbs = styled.div`
  display: flex;
  margin-bottom: 40px;
`
export const ContactUsBreadCrumbsLink = styled(LinkRouter)`
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
  margin-bottom: auto;
  margin-top: auto;
`
export const ContactText = styled.div`
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: white;
  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
  margin: 20px 0px;
`
export const ContactsCardContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    display: grid;
  }
`
export const ContactsCard = styled.a`
  color: black;
  text-decoration: none;
  border-radius: 4px;
  max-width: 400px;
  padding: 40px 40px;
  background: white;
  display: grid;
  justify-content: center;

  margin: 20px;

  svg {
    margin-left: auto;
    margin-right: auto;

    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.2rem;
    text-align: center;

    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }

  p {
    font-size: 1rem;
    text-align: center;
  }

  box-shadow: 0 6px 6px rgba(0, 0.4, 0, 0.4);
  transition: all 0.2s ease-in-out;

  &:hover {
    // background-color: #b9acac;
    box-shadow: 0 4px 5px rgba(0, 0.6, 0, 0.6);
  }
`
export const ContactImgWrap = styled.div`

  // min-width: 40%;
  width: 400px;
  height: auto;

  // float:right;
  // display:block;
`
export const ContactImg = styled.img`

  // min-width: 40%;
  // width: 400px;
  // height: auto;

  // float:right;
  // display:block;
`
