import "./ChangeTypeElement.css"

const ChangeTypeElement = ({ isOn, handleToggle, onColor }) => {
  return (
    <label
      style={{
        background: isOn && onColor,
        margin: "1rem auto",
      }}
      className="react-switch"
    >
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        type="checkbox"
      />
      <div className="react-switch-button" />
      <div className="react-switch-labels" style={{ textAlign: "center" }}>
        <span style={{ color: !isOn && "black",  fontWeight: "bold"  }}>LH</span>
        <span style={{ color: isOn && "black",  fontWeight: "bold"  }}>RH</span>
      </div>
    </label>
  )
}

export default ChangeTypeElement
