import styled from "styled-components"
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const FirstBlockWrap = styled.div`
  background: black;
  height: 600px;

  padding: 0 100px;

  gap: 6rem;
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `"col2 col1"` : `"col1 col2"`};

  @media screen and (max-width: 1200px) {
    padding: 0 60px;
    gap: 2rem;
  }
  @media screen and (max-width: 1000px) {
    padding: 40px 20px;
    display: block;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 10px;
    display: block;
    height: auto;
  }
`
export const FirstBlockColumn1 = styled.div`
  grid-area: col1;

  text-align: center;

  @media screen and (max-width: 1000px) {
    margin: 30px 0px 40px 0px;
  }

  // animation: ${({ isScroll }) =>
    isScroll ? "transform 2s linear" : "none"};

  // @keyframes transform {
  //   from {
  //     transform: translate(-50vw, 0vh)
  //     opacity: 0
  // }
  // to {
  //     // transform: translate(10vw, 0vh)
  //     opacity: 1
  // }
  // }

  // opacity: 0;

  // @keyframes transform {
  //   0% {
  //     display: none;
  //     opacity: 0;
  //     transform: translate(-50vw, 0vh);
  //   }

  //   1% {
  //     display: block;
  //     opacity: 0;
  //   }

  //   100% {
  //     display: block;
  //     opacity: 1;
  //   }
  // }
`

export const VideoWrap = styled.div`
  // width: 100%;
  // height: 100%;
  // --o-object-fit: cover;
  // object-fit: cover;
  // background: #232a34;

  video {
    width: 100%;
  }
`

export const ImgWrap = styled.div`
  // max-width: 480px;
  height: 100%;

  // transform: translate(calc(-100vw - calc(1vw * var(--scroll))), calc(0vh));

  @media screen and (max-width: 1000px) {
    margin: 20px 0px;
  }
`

export const Img = styled(LazyLoadImage)`
  // width: 340px;
  // width: 100%;
  // height: 100%;
  padding-right: 0;

  width: 540px;
  height: auto;
  // padding: 1rem 0rem;

  @media screen and (max-width: 768px) {
    width: 420px;
    // padding: 1rem 2rem 0rem 2rem;
  }

  @media screen and (max-width: 480px) {
    width: 280px;
    padding: 1rem 0;
  }

  @media screen and (max-width: 320px) {
    width: 240px;
    padding: 1rem 0;
  }
`

export const FirstBlockColumn2 = styled.div`
  padding: 0 15px;
  grid-area: col2;

  text-align: center;

  // animation: ani 2.5s forwards;

  @media screen and (max-width: 768px) {
    animation: ${({ isScroll }) =>
      isScroll ? "animation: ani 2.5s linear" : "none"};
    // opacity: 0;

    // @keyframes ani {
    //   0% {opacity: 0;}
    //   100% {opacity: 1;}
    // }

    // @keyframes transform2 {
    //   from {
    //     transform: translate(150vw, 0vh)
    //     // opacity: 0
    //   }
    //   to {
    //       transform: translate(0vw, 0vh)
    //       transform: translate(0, 0); // обнуляем

    //       opacity: 1
    //   }
    // }

    // @keyframes transform2 {
    //   0% {
    //     // display: none;
    //     opacity: 0;
    //     // transform: translate(150vw, 0vh);
    //   }

    //   1% {
    //     // display: block;
    //     opacity: 0;
    //   }

    //   100% {
    //     // display: block;
    //     opacity: 1;
    //   }
    // }
  }
`

export const FirstBlockColumn2H1 = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`
export const FirstBlockColumn2P = styled.p`
  font-size: 1.4rem;
  color: white;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    // margin-bottom: 2rem;
  }
`

export const FirstBlockBtnWrapper = styled.div`
  // float: left;

  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 100;
  // @media screen and (max-width: 768px) {
  //   button {
  //     padding: 5px 10px;
  //   }
  // }
`
export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`
export const FirstBlockSectionButton = styled(Link)`
  white-space: nowrap;
  padding: 12px 30px;
  color: yellow;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  border: 1px solid yellow;

  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    // background: ${({ primary }) => (primary ? "#fff" : "yellow")};
    // color: white;
  }
  @media screen and (max-width: 768px) {
    padding: 8px 12px;
    svg {
      margin-top: 2px;
    }
    font-size: 14px;
  }
`
