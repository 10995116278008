import styled from "styled-components"
import { Link as LinkRouter } from "react-router-dom"

export const ShopContainer = styled.div`
  height: auto;
  min-height: 100vh;
  background: black;
  // background: #e9eaec;
  padding: 0px 80px;

  @media screen and (max-width: 1200px) {
    padding: 0px 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`
export const ShopWrapper = styled.div`
  height: auto;
  padding: 60px 0;
`

export const ShopBreadCrumbs = styled.div`
  display: flex;
  margin-bottom: 40px;
`
export const ShopBreadCrumbsLink = styled(LinkRouter)`
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
  margin-bottom: auto;
  margin-top: auto;
`

export const H1Wrap = styled.div`
  // width: 100%;
  padding: 16px 20px;
  background-color: white;
  margin: 20px 0px;
`

export const ShopItemListWrap = styled.div`
  // max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 2rem;
  align-items: center;
  // grid-gap: 50px;

  // @media screen and (max-width: 1200px) {
  //   grid-template-columns: 1fr 1fr;
  // }
  // @media screen and (max-width: 768px) {
  //   grid-template-columns: 1fr;
  //   padding: 0 20px;
  // }
`

export const ItemCard = styled.div`
  height: 100%;
  // max-width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  // padding: 30px 20px;
  // box-shadow: 0 1px 3px rgba(0, 0.2, 0, 0.2);
  transition: all 0.2s ease-in-out;

  background: black;
  // background: white;

  text-decoration: none;

  &:hover {
    // background-color: #b9acac;
    box-shadow: 0 2px 4px rgba(0, 0.4, 0, 0.4);
  }
`

export const ItemImageWrap = styled(LinkRouter)`
  padding: 20px 10px;

  @media screen and (max-width: 768px) {
    padding: 40px 10px;
  }
`

export const ItemImage = styled.img`
  // height: 50%;
  // width: 360px;
  // width: 100%;
  // height: auto;
  border-radius: 4px;
  // margin-bottom: 14px;

  width: 240px;
  height: auto;
  // padding: 1rem 0rem;

  @media screen and (max-width: 768px) {
    width: 260px;
    // padding: 1rem 2rem 0rem 2rem;
  }

  @media screen and (max-width: 480px) {
    width: 280px;
    padding: 1rem 0;
  }

  @media screen and (max-width: 320px) {
    width: 220px;
    padding: 1rem 0;
  }
`

export const ItemTextWrap = styled.div`
  // max-width: 360px;
`

export const ItemH1 = styled.h1`
  font-size: 1rem;
  // color: #4b4e44;
  color: white;
  height: 50px;
  text-align: center;
  line-height: 1.2rem;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.4rem;
  }
`

export const ItemH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: left;
`
export const ItemH3 = styled(LinkRouter)`
  text-decoration: none;
  font-size: 1.04rem;
  font-weight: 500;
  color: white;
  // color: hsla(0, 0%, 9%, 0.5);
  transition: color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
  margin: auto 0;
  text-align: left;
  font-weight: bold;
`

export const ItemP = styled.p`
  font-size: 1rem;
  text-align: left;
`

export const ItemBtn = styled.button`
  display: flex;
  padding: 6px 20px;
  cursor: pointer;
  svg {
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 6px;
  }

  border-radius: 4px;
`

// export const ItemP = styled.p`
//   font-size: 1rem;
//   text-align: center;
// `

export const ViewWrap = styled.div`
  display: flex;
  justify-content: center;
  // margin-top: 1.4rem;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`
export const ViewLinkWrap = styled.div`
  justify-content: center;
  display: flex;
  padding: 1rem;
  background: rgb(233, 234, 236);
  /* background: grey; */
  border-radius: 18px;
`
export const ItemPriceWrap = styled.div`
  display: flex;
  gap: 0.6rem;
  font-weight: 600;
  margin-bottom: 0.7rem;

  p {
    color: white;
  }

  @media screen and (max-width: 768px) {


    p {
      margin: 0 auto;
      margin-bottom: 0.8rem;
    }
  }
  @media screen and (max-width: 480px) {
  }
`

export const ItemShortDescriptionWrap = styled.div`
color: white;

  @media screen and (max-width: 768px) {
    max-width: 80%;
    text-align: center;
    display: flex;
    margin: 0 auto;

    p {
      fontsize: 0.8rem;
      margin: 0.4rem 0;
      color: white;
      min-height: 40px;
    }
  }
  @media screen and (max-width: 480px) {
  }
`
