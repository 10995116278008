import "./App.css"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import Shop from "./pages/Shop"
import Navbar from "./components/Navbar"
import React, { useEffect, useState } from "react"
import Sidebar from "./components/Sidebar"
import { MenuContext } from "./context/menuContext"
import NotFound from "./pages/NotFound"
import ItemPage from "./pages/ItemPage"
import Footer from "./components/Footer"
import Cart from "./pages/Cart"
import { ContextProvider } from "./context/shopContext"
import "./fonts/fonts.css"
import Gallery from "./pages/Gallery"
import ContactUs from "./pages/ContactUs"
import AboutCenterpins from "./pages/AboutCenterpins"
import Privacy from "./pages/PrivacyPolicy"
import ReturnsExchanges from "./pages/ReturnsExchanges"

import { HelmetProvider } from "react-helmet-async"
import AboutItemPage from "./pages/AboutCenterpins/AboutItemPage"

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isHome, setIsHome] = useState(true)
  const [width, setWidth] = useState(window.innerWidth)

  const toggleNavbar = () => setIsSidebarOpen(!isSidebarOpen)
  const togglePages = () => setIsHome(window.location.pathname === "/")

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])
  useEffect(() => {
    if (window.location.pathname === "/") setIsHome(true)
    else setIsHome(false)
  }, [window.location.pathname])
  const handleWindowSizeChange = () => setWidth(window.innerWidth)

  return (
    <HelmetProvider>
      <MenuContext.Provider
        value={{
          isSidebarOpen,
          toggleNavbar,
          isHome,
          togglePages,
          width,
        }}
      >
        <BrowserRouter>
          <ContextProvider>
            <Navbar />
            <Sidebar />
            <main style={{ background: "#f1f1f1" }}>
              {/* <Route path="*" element={<Home />} /> */}
              <Routes>
                <Route
                  path="/"
                  element={<Home />}
                  exact
                  onClick={() => setIsHome(true)}
                />

                <Route
                  path="/centerpins"
                  element={<AboutCenterpins />}
                  exact
                  onClick={() => setIsHome(true)}
                />
                <Route
                  path="/shop"
                  element={<Shop />}
                  onClick={() => setIsHome(false)}
                />
                <Route
                  path="/gallery"
                  element={<Gallery />}
                  onClick={() => setIsHome(false)}
                />
                <Route
                  path="/contact"
                  element={<ContactUs />}
                  onClick={() => setIsHome(false)}
                />
                <Route
                  path="/cart"
                  element={<Cart />}
                  onClick={() => setIsHome(false)}
                />
                <Route
                  path="/privacy-policy"
                  element={<Privacy />}
                  onClick={() => setIsHome(false)}
                />
                <Route
                  path="/returns-and-exchanges"
                  element={<ReturnsExchanges />}
                  onClick={() => setIsHome(false)}
                />
                <Route path="/shop/:id" element={<ItemPage />} />
                <Route path="/centerpins/:id" element={<AboutItemPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </ContextProvider>
          <Footer />
        </BrowserRouter>
      </MenuContext.Provider>
    </HelmetProvider>
  )
}

export default App
