import { useState } from "react"
import { AboutNavBtn, ArrowForward, ArrowRight } from "./AboutNavItemElements"

const AboutNavItem = ({ text, link }) => {
  const [hover, setHover] = useState(false)
  // const onHover = () => {
  //   setHover(!hover)}
  return (
    <AboutNavBtn
      to={link}
      //   className="myButton"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      duration={1000}
      exact="true"
      offset={-80}
      smooth={true}
    >
      <p>{text}</p>
      {hover ? <ArrowForward /> : <ArrowRight />}
    </AboutNavBtn>
  )
}

export default AboutNavItem
