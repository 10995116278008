export const total_price = (current_price, count) =>
  current_price.substring(1) * count

export const teleUrl = (apiToken, chatId, text) =>
  `https://api.telegram.org/bot${apiToken}/sendMessage?chat_id=${chatId}&text=${text}&parse_mode=HTML`

export const teleMessage = (order, total = 0, data, isPpok = false) => { 
  console.log(order, total, data, isPpok)
  return `<i><b>New%20request:</b></i>%0AFrom:%20${data.firstname}%20${
    data.lastname
  }%0AEmail:%20${data.email}%20%0ACompany%20(not%20necessary):%20${
    data.company
  }%0ARegion:%20${data.country}%0AAddress:%20${
    data.address
  }%0AFlat%20(not%20necessary):%20${data.apartment}%0ACity:%20${
    data.city
  }%0AIndex:%20${data.zip_code}%0AComments:%20${
    data.comments
  }%0APhone%20(not%20necessary):%20${data.phone}%0AOrder:%20${order.map((item) => {
    return `${item.name}%20${item.clockwise_type}%20x%20${item.quantity}%20`
  })}%0ATotal:%20$${total}%0APayment:%20${isPpok ? "Made%20by%20PP" : "not made"}`}
// export const teleMessage = (order, total, data, isPpok = false) => { 
//   console.log(order, total, data, isPpok)
//   return `<i><b>Новая%20заявка:</b></i>%0AОт:%20${data.firstname}%20${
//     data.lastname
//   }%0AПочта:%20${data.email} %0AКомпания%20(не%20обязательно):%20${
//     data.company
//   }%0AСтрана:%20${data.country}%0AАдрес:%20${
//     data.address
//   }%0AКвартира%20(не%20обязательно):%20${data.apartment}%0AГород:%20${
//     data.city
//   }%0AИндекс:%20${data.zip_code}%0AКомментарии:%20${
//     data.comments
//   }
//   %0AТелефон%20(не%20обязательно):%20${data.phone}%0AЗаказ:%20${order.map((item) => {
//     return ` ${item.name}%20${item.clockwise_type}%20x%20${item.quantity}%20шт.%20`
//   })}%0AИтог:%20$${total}%0AОплата:%20${isPpok ? "Произведена%20через%20ПП" : "нет"}
//   `}

export const teleMinMessage = (data) =>
  `<i><b>New%20request:</b></i> %0AFrom:%20${data.name}%0AEmail:%20${data.email}%0APhone:%20${data.phone}`
