import styled from "styled-components"
import { Link as LinkRouter } from "react-router-dom"

export const AboutContainer = styled.div`
  height: auto;
  min-height: 100vh;
  background: black;
  // background: #f1f1f1;
  padding: 0px 80px;

  @media screen and (max-width: 1200px) {
    padding: 0px 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 0px 0px;
  }
`
export const AboutWrapper = styled.div`
  height: auto;
  padding: 40px 0;
`
export const AboutBreadCrumbs = styled.div`
  display: flex;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }
`
export const AboutBreadCrumbsLink = styled(LinkRouter)`
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
  margin-bottom: auto;
  margin-top: auto;

  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
  }
`

export const AboutHeadWrapper = styled.div`
  // padding: 16px 20px;
  background-color: white;
  // margin: 0px 0px 20px 0px;

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`

export const Introduction = styled.div`
  // padding: 0px 20px;
  // text-align: justify;
  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`
export const DropdownWrap = styled.div`
  padding: 20px 20px;
`
// export const DropdownList = styled(Dropdown)`
//   li a {
//     text-decoration: none;
//     color: #2d2f31;
//   }

//   nav {
//     width: 300px;
//     background: #d9d9d9;
//     margin: 40px auto;
//   }

//   span {
//     padding: 30px;
//     background: #2d2f31;
//     color: white;
//     font-size: 1.2em;
//     font-variant: small-caps;
//     cursor: pointer;
//     display: block;
//   }

//   span::after {
//     float: right;
//     right: 10%;
//     content: "+";
//   }

//   .slide {
//     clear: both;
//     width: 100%;
//     height: 0px;
//     overflow: hidden;
//     text-align: center;
//     transition: height 0.4s ease;
//   }

//   .slide li {
//     padding: 30px;
//   }

//   #touch {
//     position: absolute;
//     opacity: 0;
//     height: 0px;
//   }

//   #touch:checked + .slide {
//     height: 300px;
//   }
// `
export const AboutTextWrapper = styled.div`
  padding: 20px 0px;
  // background: bisque;
  color: white;

  line-height: 1.26rem;
  // @media screen and (max-width: 768px) {
  //   padding: 20px 10px;
  // }
`

export const BtnWrap = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 1000px) {
    justify-content: center;
    padding: 0 1rem;
  }
`
export const BtnToShop = styled(LinkRouter)`
  background: #ffe500;
  // background: white;
  color: black;
  font-weight: bold;
  border: none;
  cursor: pointer;
  text-decoration: none;
 
  font-size: 1rem;
  padding: 0.8rem 1.6rem;

  border-radius: 2px;

  &:hover {
    background-color: #b9acac;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`
