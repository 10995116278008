import { CountItemWrap } from "./CartElements"

const CountItem = ({
  decQuantity,
  itemId,
  incQuantity,
  quantity,
  clockwise_type,
}) => {
  return (
    <CountItemWrap>
      <p
        style={{ cursor: "pointer" }}
        onClick={() => decQuantity(itemId, clockwise_type)}
      >
        -
      </p>
      {quantity}
      <p
        style={{ cursor: "pointer" }}
        onClick={() => incQuantity(itemId, clockwise_type)}
      >
        +
      </p>
    </CountItemWrap>
  )
}

export default CountItem
