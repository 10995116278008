const PageHeadWrapper = ({ text }) => {
  return (
    <div
      style={{
        // padding: "16px 20px",
        backgroundColor: "black",
        margin: "1rem 0 1rem 0",
        color: "white"
      }}
    >
      <h2>{text}</h2>
    </div>
  )
}

export default PageHeadWrapper
