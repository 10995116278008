import {
  FooterContainer,
  FooterIconLink,
  FooterIconsWrap,
  FooterLink,
  FooterLinkWrap,
  FooterMail,
  FooterMailWrap,
  FooterWrap,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElements"

import { FaYoutube, FaInstagram, FaAmazon, FaTiktok } from "react-icons/fa"
import { FiMail } from "react-icons/fi"

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">ARMREELS</SocialLogo>
            <FooterLinkWrap style={{ display: "grid", gap: "0.2rem" }}>
              <FooterLink
                to="contact"
                onClick={() => {
                  try {
                    window.gtag("event", "ToContact", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                CONTACT US
              </FooterLink>
              <FooterLink
                to="privacy-policy"
                onClick={() => {
                  try {
                    window.gtag("event", "ToPrivacyPolicy", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                PRIVACY POLICY
              </FooterLink>
              <FooterLink
                to="returns-and-exchanges"
                onClick={() => {
                  try {
                    window.gtag("event", "ToReturnsAndExchanges", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                RETURNS & EXCHANGES
              </FooterLink>
            </FooterLinkWrap>
            <FooterMailWrap>
              <FiMail style={{ color: "white" }} />
              <FooterMail>sales@armreels.com</FooterMail>
            </FooterMailWrap>
            <FooterIconsWrap>
              <FooterIconLink
                href="https://www.tiktok.com/@armreels_centerpins?_t=8cmAfWEetcH"
                target="_blank"
                onClick={() => {
                  try {
                    window.gtag("event", "toTTFromFooter", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                <FaTiktok />
              </FooterIconLink>
              <FooterIconLink
                href="https://www.instagram.com/armreels_centerpin/"
                target="_blank"
                onClick={() => {
                  try {
                    window.gtag("event", "toIMFromFooter", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                <FaInstagram />
              </FooterIconLink>
              <FooterIconLink
                href="https://youtube.com/@armreel_centerpin"
                target="_blank"
                onClick={() => {
                  try {
                    window.gtag("event", "toYTFromFooter", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                <FaYoutube />
              </FooterIconLink>
              <FooterIconLink
                href="https://a.co/d/fgF5j2j"
                target="_blank"
                onClick={() => {
                  try {
                    window.gtag("event", "toAMFromFooter", {
                      event_name: "click",
                    })
                  } catch (error) {
                    console.log(error)
                  }
                }}
              >
                <FaAmazon />
              </FooterIconLink>
            </FooterIconsWrap>
            <WebsiteRights>
              ARMREELS © {new Date().getFullYear()} Copyright Text
            </WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
