import { HiOutlineShoppingCart } from "react-icons/hi"
import { Badge } from "./NavbarElements"
import styled from "styled-components"

const BasketIcon = styled(HiOutlineShoppingCart)`
  color: #fff;
  width: 20px;
  height: 20px;

  @media screen and (max-width: 768px) {
    width: 24px;
    height: 24px;
    margin-top: 6px;
  }

  @media screen and (max-width: 480px) {
    width: 26px;
    height: 26px;
    margin-top: 8px;
    
  }

  @media screen and (max-width: 320px) {
  }
`

const Basket = ({ allCountInCart }) => {
  return (
    <>
      <BasketIcon/>
      {allCountInCart !== 0 && (
        <Badge>{allCountInCart ? allCountInCart : 0}</Badge>
      )}
    </>
  )
}

export default Basket
