import styled from "styled-components"

export const ReturnsContainer = styled.div`
  padding: 2rem 4rem;
  text-align: center;
  height: auto;
  min-height: 100vh;

  background: black;
  color: white;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`
export const ReturnsH1 = styled.h1`
  margin: 1rem 0;

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`
export const ReturnsP = styled.p`
  // margin: 1rem 0;
  font-size: 1.4rem;
  text-align: justify;
`
