import { useForm } from "react-hook-form"
import { FaWhatsapp } from "react-icons/fa"
import { AiOutlineCheck } from "react-icons/ai"
import { teleMinMessage, teleUrl } from "../../../helpers"
import { Link } from "react-router-dom"
import styled from "styled-components"

const FirstFormContainer = styled.div`
  // background: #1a191a;
  background: black;

  display: grid;
  gap: 2rem;
  justify-content: center;

  padding: 2rem 8rem;

  @media screen and (max-width: 798px) {
    padding: 2rem 2rem;
  }
`
const H1Wrap = styled.div`
  //   width: 100%;

  h1 {
    font-size: 1.6rem;
    text-align: center;
    color: white;
  }
`
const TextWrap = styled.div`
  font-size: 1rem;

  h2 {
    text-align: center;
    color: white;

    @media screen and (max-width: 798px) {
      font-size: 1.2rem;
    }
  }
`
const Form = styled.form`
  display: grid;
  justify-content: center;
  gap: 2rem;
`
const FormWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;

  @media screen and (max-width: 1000px) {
    gap: 4rem;
  }

  @media screen and (max-width: 798px) {
    font-size: 1.2rem;
    display: grid;
    gap: 2rem;

    justify-content: center;
  }
`
const FormInput = styled.input`
  height: 46px;
  // width: 200px;
  width: 100%;
  // border-radius: 6px;
  // border: 1px solid grey;
  border: none;
  background: inherit;
  border-bottom: 1px solid white;
  padding: 0 1rem;
  color: white;
  font-size: 0.9rem;
  ::placeholder {
    font-size: 0.9rem;
    color: white;
  }

  &:focus {
    // background: bisque;
    border: none;
    box-shadow: none;
  }

  @media screen and (max-width: 798px) {
    //   width: 100%;
    // width: 300px;
  }

  @media screen and (max-width: 340px) {
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    // width: 200px;
  }
`
const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`
const BtnSend = styled.button`
  //   margin: 0 0 1.4rem 0;
  white-space: nowrap;
  padding: 10px 30px;

  font-weight: bold;
  background: #ffe500;
  color: black;

  font-size: 16px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  border: 1px solid yellow;
  border-radius: 2px;
  @media screen and (max-width: 768px) {
    padding: 0.8rem 1.2rem;
    font-size: 14px;
  }
`
export const FormError = styled.div`
  font-size: 0.8rem;
  color: white;
  margin-left: 1rem;
  margin-top: 0.4rem;
`

const FirstForm = ({ handleMinFormDelivery, isFormSending = false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
    },
    mode: "onChange",
  })

  const handleRegistration = async (data) => {
    try {
      let apiToken = process.env.REACT_APP_API_KEY
      let chatId = process.env.REACT_APP_CHAT_ID
      console.log(data)

      let text = teleMinMessage(data)

      let urlString = teleUrl(apiToken, chatId, text)
      console.log(urlString)
      let request = new XMLHttpRequest()

      request.open("GET", urlString)
      request.send()

      try {
        // window.gtag("event", "sendMinForm", { event_name: "click" })
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }

    reset() // reset form
    handleMinFormDelivery()
  }
  const handleError = (errors) => {}

  return (
    <FirstFormContainer id="firstForm">
      <H1Wrap>
        <h1>GET AN OFFER</h1>
      </H1Wrap>
      <TextWrap>
        <h2>
          Our specialist will contact you and help you choose the centerpin
        </h2>
      </TextWrap>
      <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
        <FormWrap>
          <div>
            <FormInput {...register("email")} type="text" placeholder="Email" />
          </div>
          <div>
            <FormInput {...register("name")} type="text" placeholder="Name" />
          </div>
          {/* <div style={{display:"flex", justifyContent:"space-between"}}> */}
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FaWhatsapp
                style={{
                  color: "#25d366",
                  // margin: "0.8rem 0 0 1rem",
                  margin: "auto 1rem",
                  width: "26px",
                  height: "26px",
                }}
              />
              <FormInput
                {...register("phone", {
                  required: "WhatsApp is required",
                  pattern: {
                    value: /(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?/,
                    message: "Please enter valid phone with the country's code",
                  },
                })}
                type="text"
                placeholder="WhatsApp Number"
              />
            </div>

            <div style={{ marginLeft: "2rem" }}>
              {errors?.phone && <FormError>{errors.phone.message}</FormError>}
            </div>
          </div>

          {/* </div> */}
        </FormWrap>
        <BtnWrap>
          <BtnSend>SUBMIT</BtnSend>
        </BtnWrap>
      </Form>
      {isFormSending ? (
        <div style={{ display: "flex", margin: "0 auto" }}>
          <AiOutlineCheck style={{ color: "white", margin: "auto 1rem" }} />
          <p style={{ color: "white" }}>The form was sent successfully.</p>
        </div>
      ) : (
        <div style={{ display: "flex", margin: "0 auto" }}>
          <p style={{ color: "white" }}>
            By submitting a request, you agree to the{" "}
            <Link
              to="/privacy-policy"
              style={{ textDecoration: "none", color: "#ffe500" }}
              onClick={() => {
                try {
                  window.gtag("event", "ToPrivacyByForm", {
                    event_name: "click",
                  })
                } catch (error) {
                  console.log(error)
                }
              }}
            >
              privacy policy
            </Link>.
          </p>
        </div>
      )}
    </FirstFormContainer>
  )
}

export default FirstForm
