export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_SHOW_ITEM": {
      return {
        ...state,
        showItem: payload || null,
      }
    }
    case "SET_GOODS": {
      return {
        ...state,
        goods: payload || [],
        loading: false,
      }
    }
    case "ADD_TO_BADGE": {
      return {
        ...state,
        allCountInCart: state.allCountInCart + payload,
      }
    }
    case "ADD_TO_BASKET": {
      const itemIndex = state.order.findIndex(
        (orderItem) => orderItem.vendor_code === payload.item.vendor_code
      )
      let newOrder = null
      if (itemIndex < 0) {
        const newItem = {
          ...payload.item,
          quantity: payload.count,
          clockwise_type: payload.clockwise_type,
        }
        newOrder = [...state.order, newItem]
      }
      else {
        if (state.order[itemIndex].clockwise_type === payload.clockwise_type) {
          newOrder = state.order.map((orderItem, index) => {
            if (
              index === itemIndex &&
              orderItem.clockwise_type === payload.clockwise_type
            ) {
              return {
                ...orderItem,
                quantity: orderItem.quantity + payload.count,
              }
            }
            // else if (
            //   index === itemIndex &&
            //   orderItem.clockwise_type !== payload.clockwise_type
            // )
            // {

            // }
            else {
              return orderItem
            }
          })
        }
        else {
          const newItem = {
            ...payload.item,
            quantity: payload.count,
            clockwise_type: payload.clockwise_type,
          }
          newOrder = [...state.order, newItem]
        }
      }

      return {
        ...state,
        order: newOrder,
      }
    }
    case "CLEAR_BASKET":
      return {
        ...state,
        order: [],
        allCountInCart: 0,
      }
    case "REMOVE_FROM_BASKET":
      return {
        ...state,
        order: state.order.filter(
          (elem) =>
            elem.vendor_code !== payload.itemId ||
            (elem.vendor_code === payload.itemId &&
              elem.clockwise_type !== payload.clockwise_type)
        ),
        allCountInCart:
          state.allCountInCart -
          state.order.filter(
            (elem) =>
              elem.vendor_code === payload.itemId &&
              elem.clockwise_type === payload.clockwise_type
          )[0].quantity,
      }
    case "CLOSE_ALERT":
      return {
        ...state,
        alertName: "",
      }
    case "INCREMENT_QUANTITY":
      return {
        ...state,
        order: state.order.map((elem) => {
          if (
            elem.vendor_code === payload.itemId &&
            elem.clockwise_type === payload.clockwise_type
          ) {
            const newQuantity = elem.quantity + 1
            return { ...elem, quantity: newQuantity }
          } else {
            return elem
          }
        }),
        allCountInCart: state.allCountInCart + 1,
      }
    case "DECREMENT_QUANTITY":
      if (
        state.order.find(
          (item) =>
            item.vendor_code === payload.itemId &&
            item.clockwise_type === payload.clockwise_type
        ).quantity === 1
      ) {
        return {
          ...state,
          order: state.order.filter(
            (elem) =>
              elem.vendor_code !== payload.itemId ||
              (elem.vendor_code === payload.itemId &&
                elem.clockwise_type !== payload.clockwise_type)
          ),
          allCountInCart:
            state.allCountInCart -
            state.order.filter(
              (elem) =>
                elem.vendor_code === payload.itemId &&
                elem.clockwise_type === payload.clockwise_type
            )[0].quantity,
        }
      }

      return {
        ...state,
        order: state.order.map((elem) => {
          if (
            elem.vendor_code === payload.itemId &&
            elem.clockwise_type === payload.clockwise_type
          ) {
            const newQuantity = elem.quantity - 1
            return {
              ...elem,
              quantity: newQuantity >= 0 ? newQuantity : 0,
            }
          } else {
            return elem
          }
        }),
        allCountInCart: state.allCountInCart - 1,
      }
    case "TOGGLE_BASKET":
      return {
        ...state,
        isBasketShow: !state.isBasketShow,
      }
    default:
      return state
  }
}
