import { ElementContainer, ElementHead, TextBlockWrapper } from "./Elements"
import { BtnToShop, BtnWrap } from "../../AboutCenterpinsElements"
import FirstForm from "../../../../components/HomeHeroSection/FirstForm"

const AboutArmreel45E = () => {
  return (
    <>
      <ElementContainer id="about-Armreel-45-E">
        <ElementHead>Armreel-45-E</ElementHead>
        {/* <ElementH2>Why is Armreel 45 better than others?</ElementH2> */}
        <TextBlockWrapper>
          Enlarged Armreel-45 with increased fishing line capacity.
          <br />
          <br />
          Right—hand - winding clockwise.
          <br />
          Left—hand - winding counterclockwise.
          <br />
          <br />
          The Armreel-45-E model is used for a variety of fishing styles, but
          its advantages are most fully revealed in floatfishing (Bologna,
          Nottingham) and spinning.
          <br />
          <br />
          The reel parts are made of anodised aviation alloy AA2024 and
          high-strength steel.
          <div style={{ padding: "1rem 1rem" }}>
            <b>Features:</b>
            <ul
            // style={{ listStyleType: "none" }}
            >
              <li>Casting brakes</li>
              <li>Brake pad</li>
              <li>Tension knob</li>
              <li>Anti-reverse clicker</li>
              <li>Oil port</li>
              <li>Sand-resist assembly</li>
            </ul>
          </div>
          Allows to cast baits weighing from 1/14 oz. (with proper training).
          Allows to cast super-heavy baits. Designed to catch trophy fish. Reel
          has an exceptionally powerful thrust, the design has a huge margin of
          safety. The reel is resistant to sand, dirt, water and requires
          minimal maintenance.
          <br />
          <br />
          Does not require the use of a special rod. It is possible to use
          multifilament lines with a strength of up to 40 lbs, which
          significantly reduces the likelihood of breakage and loss of baits.
          Oil port allows to lubricate bearings without disassembling the spool.
          <div style={{ padding: "1rem 1rem" }}>
            <b>SPEC:</b>
            <ul
            // style={{listStyleType: "none"}}
            >
              <li>Mass: 7,7 oz. / 220 gramms</li>
              <li>Spool diam: 4.5 in. / 114 mm</li>
              <li>Spool width: 1.0 in. / 23 mm </li>
              <li>Capacity: 200 yds/185 m (line 0.35 mm)</li>
              <li>Rotation: 2 x ABEC-7 marine-grade bearings</li>
              <li>Tractive effort: 35 lbs/15 kg</li>
              <li>
                Warranty: The warranty against manufacturing defects is 5 years.
                Since 2009 of the master's work, only 0.1% of buyers have
                applied for warranty service.
              </li>
            </ul>
          </div>
        </TextBlockWrapper>
        <BtnWrap>
          <BtnToShop to="/shop/Armreel-45-E">BUY NOW</BtnToShop>
        </BtnWrap>
        <FirstForm />
      </ElementContainer>
    </>
  )
}

export default AboutArmreel45E
