import styled from "styled-components"
import { Link } from "react-router-dom"

export const PrivacyContainer = styled.div`
  padding: 2rem 4rem;
  text-align: center;
  height: auto;
  min-height: 100vh;

  background: black;
  color: white;

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem;
  }
`
export const PrivacyH1 = styled.h1`
  margin: 1rem 0;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`
export const PrivacyH2 = styled.h2`
  margin: 1rem 0;
  text-align: left;
  font-size: 1.6rem;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`
export const PrivacyH3 = styled.h3`
  margin: 1rem 0;
  text-align: left;
  font-size: 1.6rem;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`
export const PrivacyP = styled.p`
  // margin: 1rem 0;
  font-size: 1.4rem;
  text-align: justify;
`
