import styled from "styled-components"
import { Link as LinkRouter } from "react-router-dom"

export const CartContainer = styled.div`
  background: #f1f1f1;
  background: black;
  padding: 0px 80px;
  min-height: 100vh;
  height: auto;

  @media screen and (max-width: 1200px) {
    padding: 0px 60px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 300px) {
    padding: 0 10px;
  }
`

export const CartWrapper = styled.div`
  height: auto;
  display: grid;
  padding: 40px 0;
`

export const CartBreadCrumbs = styled.div`
  display: flex;

  margin-bottom: 40px;
`
export const CartBreadCrumbsLink = styled(LinkRouter)`
  text-decoration: none;
  font-size: 0.8rem;
  color: black;
  margin-bottom: auto;
  margin-top: auto;
`
export const ItemsCountWrap = styled.h1`
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.4rem;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`
export const CountItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  font-size: 1.3rem;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 0px 10px;
  }
  // @media screen and (max-width: 300px) {
  //   padding: 0px 10px;
  // }
`

export const H1Wrap = styled.div`
  // width: 100%;
  padding: 20px 20px;
  background-color: white;
  margin-bottom: 20px;
`
export const BtnClear = styled.div`
  background: white;
  // background: #796f6d;
  // padding: 8px 16px;
  padding: 0.6rem 1.2rem;
  color: white;
  color: black;
  border: none;
  cursor: pointer;
  font-weight: bold;

  border-radius: 4px;

  &:hover {
    background-color: #b9acac;
  }

  p {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    // padding: 6px 10px;
    width: 50%;
  }
`

export const ListWrap = styled.div`
  // width: 100%;
  
  display: grid;
  padding: 40px 60px;
  background-color: black;
  // background-color: white;
  @media screen and (max-width: 768px) {
    padding: 20px 0px;
  }
  @media screen and (max-width: 300px) {
    padding: 20px 10px;
  }
`
export const TableWrap = styled.div``

export const Table = styled.table`
  width: 100%;
  
  border-collapse: collapse;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`
export const TableHead = styled.th`
  padding-top: 1rem;
  padding-bottom: 1rem;
  
  background-color: #f1f1f1;
  // background-color: #796f6d !important;
  color: black;
  // color: white;
  font-weight: bold;

`
export const TableRow = styled.tr`
  text-align: center;
  
  color: white;
  &:hover {
    background-color: #ddd;
    color: black;
    a {
      color: black;
    }
  }
  height: 50px;

  @media screen and (max-width: 768px) {
    height: 40px;
  }
`
export const TdNameLinkWrap = styled.td`
  text-align: left;

  @media screen and (max-width: 768px) {
    height: 6rem;
  }
`
export const TdNameLink = styled(LinkRouter)`
  text-decoration: underline;
  color: white;
  // color: black;
`
export const BtnWrap = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  text-align: center;

  p {
    margin: auto 0;
  }

  @media screen and (max-width: 1000px) {
    justify-content: space-between;
    // padding: 0px 20px;
  }
`

export const BtnCheckOut = styled.div`
  // background: #796f6d;
  background: white;
  // padding: 8px 16px;
  padding: 0.6rem 1.2rem;
  width: 10rem;

  color: white;
  color: black;
  border: none;
  cursor: pointer;

  border-radius: 4px;

  &:hover {
    background-color: #b9acac;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    width: 50%;
  }
`
export const TotalH1 = styled.h1`
  color: white;
  font-size: 1.2rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin-top: auto;
    margin-bottom: auto;
  }
`
