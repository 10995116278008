import {
  AphorismContainer,
  AphorismWrap,
  GalleryContainer,
  GalleryListWrapper,
  GalleryPrimaryListWrapper,
  GalleryWrapper,
  ImgWrap,
} from "./GalleryElements"
import { LazyLoadImage } from "react-lazy-load-image-component"

// import Icon1 from "../../images/gallery/images/image1.jpg"
// import Icon2 from "../../images/gallery/images/image2.jpg"
// import Icon3 from "../../images/gallery/images/image3.jpg"
// import Icon4 from "../../images/gallery/images/image4.jpg"
// import Icon5 from "../../images/gallery/images/image5.jpg"
// import Icon6 from "../../images/gallery/images/image6.jpg"
// import Icon7 from "../../images/gallery/images/image7.jpg"
// import Icon8 from "../../images/gallery/images/image8.jpg"
// import Icon9 from "../../images/gallery/images/image9.jpg"
// import Icon10 from "../../images/gallery/images/image10.jpg"
// import Icon11 from "../../images/gallery/images/image11.jpg"
// import Icon12 from "../../images/gallery/images/image12.jpg"
// import Icon13 from "../../images/gallery/images/image13.jpg"
// import Icon14 from "../../images/gallery/images/image14.jpg"
// import Icon15 from "../../images/gallery/images/image15.jpg"
// import Icon16 from "../../images/gallery/images/image16.jpg"
// import Icon17 from "../../images/gallery/images/image17.jpg"
// import Icon18 from "../../images/gallery/images/image18.jpg"
// import Icon19 from "../../images/gallery/images/image19.jpg"
// import Icon20 from "../../images/gallery/images/image20.jpg"
// import Icon21 from "../../images/gallery/images/image21.jpg"
// import Icon22 from "../../images/gallery/images/image22.jpg"
// import Icon23 from "../../images/gallery/images/image23.jpg"
// import Icon24 from "../../images/gallery/images/image24.jpg"
// import Icon25 from "../../images/gallery/images/image25.jpg"
// import Icon26 from "../../images/gallery/images/image26.jpg"
// import Icon27 from "../../images/gallery/images/image27.jpg"
// import Icon28 from "../../images/gallery/images/image28.jpg"

// import IconPrimary1 from "../../images/home/image1.jpg"
// import IconPrimary2 from "../../images/gallery/primary/image2.jpg"
// import IconPrimary3 from "../../images/gallery/primary/image3.jpg"
// import IconPrimary4 from "../../images/gallery/primary/image4.jpg"
// import IconPrimary5 from "../../images/gallery/primary/image5.jpg"
// import IconPrimary6 from "../../images/gallery/primary/image6.jpg"

import PageHeadWrapper from "../../components/Elements/PageHeadWrapper"
import { useContext, useEffect } from "react"
import { MenuContext } from "../../context/menuContext"
import { animateScroll as scroll } from "react-scroll/modules"
import {
  BreadCrumbs,
  BreadCrumbsLink,
  BreadCrumbsP,
} from "../../components/Elements/StyledElements"
import SEO from "../../components/SEO"

const primary_data = [
  // IconPrimary1,
  // IconPrimary2,
  // IconPrimary3,
  // IconPrimary4,
  // IconPrimary5,
  // IconPrimary6,
  "https://ik.imagekit.io/armreels/home/image1.jpg?updatedAt=1685386911281",
  "https://ik.imagekit.io/armreels/Gallery/image2.jpg?updatedAt=1685475454975",
  "https://ik.imagekit.io/armreels/Gallery/image3.jpg?updatedAt=1685475454450",
  "https://ik.imagekit.io/armreels/Gallery/image4.jpg?updatedAt=1685475455198",
  "https://ik.imagekit.io/armreels/Gallery/image5.jpg?updatedAt=1685475455039",
  "https://ik.imagekit.io/armreels/Gallery/image6.jpg?updatedAt=1685475455201",
]

const data = [
  // Icon1,
  // Icon2,
  // Icon3,
  // Icon4,
  // Icon5,
  // Icon6,
  // Icon7,
  // Icon8,
  // Icon9,
  // Icon10,
  // Icon11,
  // Icon12,
  // Icon13,
  // Icon14,
  // Icon15,
  // Icon16,
  // Icon17,
  // Icon18,
  // Icon19,
  // Icon20,
  // Icon21,
  // Icon22,
  // Icon23,
  // Icon24,
  // Icon25,
  // Icon26,
  // Icon27,
  // Icon28,
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image1.jpg?updatedAt=1685561315063",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image2.jpg?updatedAt=1685561315004",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image3.jpg?updatedAt=1685561315430",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image5.jpg?updatedAt=1685561314859",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image6.jpg?updatedAt=1685561314786",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image7.jpg?updatedAt=1685561314792",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image8.jpg?updatedAt=1685561315036",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image9.jpg?updatedAt=1685561315331",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image10.jpg?updatedAt=1685561315039",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image11.jpg?updatedAt=1685561319220",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image12.jpg?updatedAt=1685561319377",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image13.jpg?updatedAt=1685561319378",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image14.jpg?updatedAt=1685561319259",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image15.jpg?updatedAt=1685561318920",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image16.jpg?updatedAt=1685561319292",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image17.jpg?updatedAt=1685561319085",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image18.jpg?updatedAt=1685561319241",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image19.jpg?updatedAt=1685561319722",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image20.jpg?updatedAt=1685561319938",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image21.jpg?updatedAt=1685561322930",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image22.jpg?updatedAt=1685561323075",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image23.jpg?updatedAt=1685561323464",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image24.jpg?updatedAt=1685561323844",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image25.jpg?updatedAt=1685561323329",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image26.jpg?updatedAt=1685561323410",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image27.jpg?updatedAt=1685561323646",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image28.jpg?updatedAt=1685561322910",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image29.jpg?updatedAt=1714497174812",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image30.jpg?updatedAt=1714497327217",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image31.jpg?updatedAt=1714497327188",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image32.jpg?updatedAt=1714497329294",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image33.jpg?updatedAt=1714497329942",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image34.jpg?updatedAt=1714497329792",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image35.jpg?updatedAt=1714497329594",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image36.jpg?updatedAt=1714497329567",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image37.jpg?updatedAt=1714497329864",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image38.jpg?updatedAt=1714497329998",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image39.jpg?updatedAt=1714497329867",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image40.jpg?updatedAt=1714497326858",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image41.jpg?updatedAt=1714497326880",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image42.jpg?updatedAt=1714497326980",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image43.jpg?updatedAt=1714497326693",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image44.jpg?updatedAt=1714497327110",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image45.jpg?updatedAt=1714497327403",
  "https://ik.imagekit.io/armreels/Gallery/NoPrimary/image46.jpg?updatedAt=1714497327335"
]

const Gallery = () => {
  const { togglePages } = useContext(MenuContext)

  useEffect(() => {
    togglePages()
    scroll.scrollToTop()
    // data.sort(function (a, b) {
    //   return 0.5 - Math.random()
    // })
  }, [])
  return (
    <>
      <SEO
        description="Armreels | Gallery | The perfect mechanism for centerpin fishing"
        title="Armreels | Gallery"
        keywords="armreels centerpin centre-pins gallery photos bait-casting air brake fishing"
        canonical="https://armreels.com/gallery"
      />
      <GalleryContainer id="gallery" className="GalleryContainer">
        <GalleryWrapper className="GalleryWrapper">
          <BreadCrumbs>
            <BreadCrumbsLink to="/">HOME</BreadCrumbsLink>
            <BreadCrumbsP>›</BreadCrumbsP>
            <BreadCrumbsLink style={{ cursor: "default" }}>
              GALLERY
            </BreadCrumbsLink>
          </BreadCrumbs>
          <PageHeadWrapper text="Gallery of your photos" />
          <AphorismContainer>
            <AphorismWrap>
              {/* Centerpin fishing allows you to feel the excitement of a primal
            angler. */}
              The main differences between centrepins from each other are the
              diameter, shape and color of the coating. Armreels centrepins is
              distinguished that allows to cast even light baits, has a minimum
              weight and sand-resistance.
            </AphorismWrap>
          </AphorismContainer>
          <GalleryPrimaryListWrapper className="primary">
            {primary_data.map((item, index) => {
              return (
                <ImgWrap key={index}>
                  <LazyLoadImage
                    effect="blur"
                    src={item}
                    alt="fish centerpin"
                  />
                </ImgWrap>
              )
            })}
          </GalleryPrimaryListWrapper>
          <AphorismContainer>
            <AphorismWrap>
              Centerpin is the effectiveness of spinning in conjunction with the
              aesthetics and emotions of fly fishing.
            </AphorismWrap>
          </AphorismContainer>
          <GalleryListWrapper>
            {data.map((item, index) => {
              return (
                <ImgWrap key={index}>
                  <img src={item} alt="fish centerpin"></img>
                </ImgWrap>
              )
            })}
          </GalleryListWrapper>
        </GalleryWrapper>
      </GalleryContainer>
    </>
  )
}

export default Gallery
