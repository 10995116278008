import { animateScroll as scroll } from "react-scroll/modules"
import { useContext, useEffect } from "react"
import HomeHeroSection from "../../components/HomeHeroSection"
import { MenuContext } from "../../context/menuContext"

const Home = () => {
  const { togglePages } = useContext(MenuContext)
  useEffect(() => {
    togglePages()
    scroll.scrollToTop()

  }, [])
  return <HomeHeroSection />
}

export default Home
