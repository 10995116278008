import ItemPageComponent from "../../components/ItemPageComponent"
import { ItemPageContainer, ItemPageWrapper } from "./ItemPageElements"

const ItemPage = () => {
  return (
    <ItemPageContainer className="ItemPageContainer">
      <ItemPageWrapper className="ItemPageWrapper"> 
        <ItemPageComponent />
      </ItemPageWrapper>
    </ItemPageContainer>
  )
}

export default ItemPage
