import { useContext, useState } from "react"
import { MenuContext } from "../../../context/menuContext"
import { total_price } from "../../../helpers"
import {
  DeliveryImage,
  DeliveryTable,
  DeliveryTableWrap,
  DeliveryWrap,
  FormWrap,
  TableItemName,
  TableItemQuantity,
  TableItemTotal,
  TableRow,
  TotalH1,
  TotalWrap,
  DeliveryСongratulations,
  DeliveryInformation,
} from "./DeliveryElements"
import Form from "./Form"
import PPButton from "./PPButton/PPButton"

const DeliveryComponent = ({ order, total, clearBasket }) => {
  const [isDelivery, setIsDelivery] = useState(false)
  const [isPpok, setIsPpok] = useState(false)

  const { isMobile } = useContext(MenuContext)

  const handleDelivery = () => setIsDelivery(true)
  return (
    <DeliveryWrap className="DeliveryWrap">
      {!isDelivery ? (
        <>
          <FormWrap className="FormWrap">
            <h2 style={{ marginBottom: "20px" }}>Delivery information</h2>
            <DeliveryInformation>
              <p>
                {/* For now, you can only use with an electronic receipt, which we
                will send to your email address along with detailed payment
                instructions.<br/><br/>We ship worldwide, please contact us for delivery
                times to your region. */}
                We ship <b>worldwide</b> with <b>USPS</b> within{" "}
                <i>2-3 weeks</i>, depending on your location and product
                category. To clarify the delivery time to your region, fill out
                the form. <b>DHL</b> fast shipping available.
                <br />
                <br />
                You use an{" "}
                {/* You can pay via <b>PayPal</b> or use an{" "} */}
                <b>electronic receipt</b> that we will send to your email
                address along with detailed payment instructions. You can also
                purchase our product on{" "}
                <a
                  href="https://a.co/d/fgF5j2j"
                  target="_blank"
                  style={{ textDecoration: "none", color: "brown" }}
                  onClick={() => {
                    try {
                      window.gtag("event", "ToAMFromCart", {
                        event_name: "click",
                      })
                    } catch (error) {
                      console.log(error)
                    }
                  }}
                >
                  Amazon
                </a> (send us the form with the comment <b>"promocode"</b> and we will
                send you a promotional code with a discount for buying on Amazon).
              </p>
            </DeliveryInformation>
            {/* <PPButton total={total} setIsPpok={setIsPpok} /> */}
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "1rem 0 0 0",
              }}
            >
              <p style={{fontWeight:"bold"}}>OR</p>
            </div>
          */}
            <Form
              order={order}
              total={total}
              clearBasket={clearBasket}
              isDelivery={isDelivery}
              handleDelivery={handleDelivery}
            />
          </FormWrap>
          <DeliveryTableWrap className="DeliveryTableWrap">
            <DeliveryTable className="DeliveryTable">
              <tbody>
                {order.map((item, index) => {
                  return (
                    <TableRow key={index} style={{}}>
                      <TableItemName>
                        {item.fullname} ({item.clockwise_type})
                      </TableItemName>

                      <TableItemQuantity>x {item.quantity}</TableItemQuantity>

                      <TableItemTotal>
                        ${total_price(item.current_price, item.quantity)}
                      </TableItemTotal>
                    </TableRow>
                  )
                })}
              </tbody>
            </DeliveryTable>
            <TotalWrap>
              <TotalH1>Total: ${total}</TotalH1>
            </TotalWrap>
            <DeliveryImage
              src="https://ik.imagekit.io/armreels/Other/photo1.jpg?updatedAt=1685473535644"
              // src={photo1}
              alt="armreels centerpins delivery"
              style={{ width: "100%", height: "auto" }}
            ></DeliveryImage>
          </DeliveryTableWrap>
        </>
      ) : (
        <DeliveryСongratulations>
          <h1 style={{ textAlign: "center" }}>Thanks for sending!</h1>
          <h2 style={{ textAlign: "center" }}>We will contact you soon.</h2>
          <p style={{ textAlign: "justify", marginTop: "2rem" }}>
            Your order has been accepted and will be processed within one day.
            You will receive an email with detailed purchase instructions to
            your email address.
          </p>
        </DeliveryСongratulations>
      )}
    </DeliveryWrap>
  )
}

export default DeliveryComponent
