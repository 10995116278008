import styled from "styled-components"

export const AboutNavContainer = styled.div`
  // padding: 10px;
  // background: bisque;
`
export const AboutNavWrapper = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    display: grid;
  }
`
// export const AboutNavBtn = styled.div`
//     padding: 20px;
//     margin: 10px;
//     background: white;
//     font-size: 1.2rem;
// `
