import styled from "styled-components"
import { Link } from "react-router-dom"

export const FooterContainer = styled.footer`
  background: #161616;
  // background: linear-gradient(15deg,#073753 0%,#56a69d 100%);
`

export const FooterWrap = styled.div`
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;

  @media screen and (max-width: 820px) {
    padding: 24px 12px;
  }
`
export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`
export const FooterLinksWrap = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`
export const FooterLinksItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`
export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`
export const FooterLinkWrap = styled.div`
  display: grid;
  // gap: 0.2rem;

  @media screen and (max-width: 820px) {
    text-align: center;
    margin-bottom: 0.4rem;
  }
`

export const FooterLink = styled(Link)`
  text-decoration: none;

  color: white;
  font-size: 0.6rem;

  &:hover {
    color: brown;
    transition: 0.3s ease-out;
  }
  @media screen and (max-width: 820px) {
    font-size: 0.8rem;
    margin: 0.4rem 0;
  }
`
export const FooterMailWrap = styled.div`
  display: flex;
  gap: 0.4rem;

  @media screen and (max-width: 820px) {
    margin: 1rem 0;
  }
`
export const FooterIconsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
  @media screen and (max-width: 820px) {
    margin: 1rem 0 2rem 0;
  }
`
export const FooterIconLink = styled.a`
  color: white;

  svg {
    width: 1.6rem;
    height: auto;
  }
`
export const FooterMail = styled.p`
  color: white;
  fontsize: 0.8rem;
  margin: auto;

  @media screen and (max-width: 820px) {
    fontsize: 1rem;
  }
`
export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`
export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;

  margin-top: auto;
  margin-bottom: auto;

  @media screen and (max-width: 820px) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;

  margin-top: auto;
  margin-bottom: auto;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`
