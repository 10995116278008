import { createContext, useReducer } from "react";
import { reducer } from '../reducer'

const initialState = {
   goods: [],
   order: [],
   loading: true,
   isBasketShow: false,
   alertName: '',
   showItem: null,
   allCountInCart: 0
}

export const ShopContext = createContext()

export const ContextProvider = ({ children }) => {
   const [value, dispatch] = useReducer(reducer, initialState)

   value.closeAlert = () => {
      dispatch({ type: 'CLOSE_ALERT' })
   }
   value.setShowItem = (item) => {
      dispatch({ type: 'SET_SHOW_ITEM', payload: item})
   }
   value.addToBasket = (item, count, clockwise_type) => {
      dispatch({ type: 'ADD_TO_BASKET', payload: {item: item, count: count, clockwise_type: clockwise_type} })
   }
   value.addToBadge = (count) => {
      dispatch({ type: 'ADD_TO_BADGE', payload: count })
   }
   value.removeFromBasket = (itemId, clockwise_type) => {
      dispatch({ type: 'REMOVE_FROM_BASKET', payload: {itemId: itemId, clockwise_type: clockwise_type} })
   }
   value.clearBasket = () => {
      dispatch({ type: 'CLEAR_BASKET'})
   }
   value.incQuantity = (itemId, clockwise_type) => {
      dispatch({ type: 'INCREMENT_QUANTITY', payload: {itemId: itemId, clockwise_type: clockwise_type} })
   }
   value.decQuantity = (itemId, clockwise_type) => {
      dispatch({ type: 'DECREMENT_QUANTITY', payload: {itemId: itemId, clockwise_type: clockwise_type}  })
   }
   value.handleBasketShow = () => {
      dispatch({ type: 'TOGGLE_BASKET' })
   }
   value.setGoods = (data) => {
      dispatch({ type: 'SET_GOODS', payload: data })
   }
   return <ShopContext.Provider value={value}>
      {children}
   </ShopContext.Provider>
}