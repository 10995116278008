import styled from "styled-components"
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md"
import { Link as LinkScroll } from "react-scroll"

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`

export const AboutNavBtn = styled(LinkScroll)`
  min-width: 240px;
  padding: 0.8rem 1.6rem;
  margin: 1rem;
  border-radius: 6px;
  color: black;

  white-space: nowrap;
  background: white;
  font-size: 1.1rem;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  // transition: all 1.6s ease-in-out;

//   border: 1px solid black;

  text-decoration: none;

  // &:hover {
  //   // transition: all 1.6s ease-in-out;
  //   // background: ${({ primary }) => (primary ? "#fff" : "yellow")};
  //   // color: white;
  // }
  @media screen and (max-width: 768px) {
    padding: 0.8rem 1.6rem;
    max-width: 290px;
    svg {
      // margin-top: 2px;
    }
  }
  @media screen and (max-width: 280px) {
    padding: 0.8rem 1.4rem;
    max-width: 260px;
    font-size: 1rem;
    margin: 0.4rem;

  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
