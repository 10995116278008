import styled from "styled-components"
import { Link as LinkScroll } from "react-scroll"

export const ElementContainer = styled.div`
  padding: 20px 20px;
  background: black;
  // background: white;
  margin: 1rem 0;
  color: white;
`
export const ElementHead = styled.h1`
  padding: 0px 10px;
  // background: white;
  margin: 20px 0px 20px 10px;
  font-size: 1.4rem;
  font-weight: bold;

  text-align: center;

  @media screen and (max-width: 768px) {
    margin: 10px 0px 20px 0px;
  }
`
export const Introduction = styled.div`
  // padding: 6px 10px;
  // background: white;
  margin: 0px 0px 20px 0px;
  // font-size: 1.2rem;
`

export const TextBlockWrapper = styled.div`
  line-height: 1.26rem;
  // padding: 0px 1rem;
  // background: white;
  margin: 0px 0px 20px 0px;
  // font-size: 1.2rem;
  // text-align: justify;
`
export const ElementH2 = styled.h2`
  font-size: 1.4rem;
  // padding: 10px 10px;
  margin: 30px 0px 20px 20px;
  text-align: center;
`
export const ElementH3 = styled.h3`
  font-size: 1.1rem;
  // padding: 10px 10px;
  margin: 30px 0px 20px 30px;
  text-align: center;
`

export const ElementVideo = styled.video`
  width: auto;
  max-height: 400px;
  float: left; 

  // margin: 0px 20px 20px 0px;

  @media screen and (max-width: 768px) {
    // width: 200px;
  }
  @media screen and (max-width: 440px) {
    width: 240px;
  }
  @media screen and (max-width: 368px) {
    width: 180px;
  }
`
export const ElementImg = styled.img`
  width: 400px;
  max-height: 400px;
  float: left; 

  @media screen and (max-width: 768px) {
    width: 300px;
  }
  @media screen and (max-width: 440px) {
    width: 240px;
  }
  @media screen and (max-width: 368px) {
    width: 180px;
  }
`
export const StyledLink = styled(LinkScroll)`
  color: blue;
  cursor: pointer;
`
export const TextLink = ({ link, text }) => {
  return (
    <StyledLink
      to={link}
      smooth={true}
      duration={500}
      spy={true}
      exact="true"
      offset={-80}
    >
      {text}
    </StyledLink>
  )
}

export const ContentBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: grid;
  }
`
export const ContentColumn = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding: 1rem 1rem;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`
export const TextColumn = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`
