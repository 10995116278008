// import { Link } from "react-router-dom"
import styled from "styled-components"
import { AiFillStar } from "react-icons/ai"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const ThirdBlockWrap = styled.div`
  background: black;
  color: white;
  min-height: 600px;

  padding: 0px 100px;

  gap: 4rem;

  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 1200px) {
    padding: 0 60px;
    gap: 2rem;
  }
  @media screen and (max-width: 1000px) {
    // padding: 40px 20px;
    // display: block;
    // height: auto;
  }
  @media screen and (max-width: 768px) {
    grid: none;
    padding: 40px 60px;
  }
  @media screen and (max-width: 400px) {
    grid: none;
    padding: 40px 20px;
  }
`
export const ThirdBlockTextWrap = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding: 1rem 1rem;

  display: grid;
  gap: 1rem;

  @media screen and (max-width: 1000px) {
    gap: 0.6rem;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
  }

  //   animation: ${({ isScroll }) =>
    isScroll ? "animation: ani 2.5s linear" : "none"};
  h1 {
    font-weight: 400;
    font-size: 2rem;
    margin-top: auto;
    margin-bottom: auto;
    @media screen and (max-width: 1000px) {
      font-size: 1.8rem;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 1.4rem;
    margin-left: 0.4rem;

    @media screen and (max-width: 1000px) {
      font-size: 1.2rem;
    }
  }
  h3 {
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 0.8rem;
    margin-bottom: auto;
    font-weight: 200;
    font-size: 1rem;
    margin-left: 2rem;
    @media screen and (max-width: 1000px) {
      margin-top: 0.6rem;
    }
  }
  h4 {
    color: gold;
    
  }
  p {
    line-height: 1.4rem;
    font-size: 1.1rem;

    @media screen and (max-width: 1000px) {
      font-size: 1rem;
      line-height: 1.3rem;
    }
  }
`
export const ThirdBlockStar = styled(AiFillStar)`
  margin-bottom: auto;
  margin-top: auto;

  color: gold;
`
export const ThirdBlockImageWrap = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin: 0 auto;

  padding: 4rem 2rem;

  justify-content: center;

  @media screen and (max-width: 1000px) {
    padding: 4rem 1rem;
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 0rem;
  }
`
export const ThirdBlockImage = styled(LazyLoadImage)`
  // width: 100%;
  // height: auto;

  //   height: 100vh;
  //   width: auto;

  // float: left;
  border-radius: 4px;
  // @media screen and (max-width: 768px) {
  //   // width: 300px;
  // }
  // @media screen and (max-width: 440px) {
  //   // width: 240px;
  // }
  // @media screen and (max-width: 368px) {
  //   // width: 180px;
  //   // width: 100%;
  // }


  
    width: 360px;
    height: auto; 

  @media screen and (max-width: 768px) {
      width: 360px;
      // padding: 1rem 2rem 0rem 2rem;
    
  }

  @media screen and (max-width: 480px) {
      width: 260px;
      // padding: 1rem 0;
    
  }

  @media screen and (max-width: 320px) {
      width: 200px;
      // padding: 1rem 0;
    
  }

`
