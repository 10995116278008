import { useContext, useEffect } from "react"
import { MenuContext } from "../../context/menuContext"
import { animateScroll as scroll } from "react-scroll/modules"
import SEO from "../../components/SEO"

const NotFound = () => {
  const { togglePages } = useContext(MenuContext)

  useEffect(() => {
    togglePages()
    scroll.scrollToTop()

    // setIsCheckOut(false)
  }, [])
  return (
    <>
        <SEO
        description="Armreels | Not found | Welcome the world of eternal classic of centerpin fishing, where the main tool is your skill, complemented by an impeccable mechanism."
        title="Armreels | Not found | The perfect mechanism for centerpin fishing"
        keywords="armreels centerpin centre-pins not found bait-casting air brake fishing"
      />
    <div
      style={{
        paddingTop: "200px",
        textAlign: "center",
        height: "100vh",
        // backgroundColor: "grey !important"
        backgroundColor:"black",
        color:"white"
      }}
      className="NotFound Wrap"
    >
      <h1 style={{ fontSize: "2rem"}}>Oops! It seems that this page does not exist.</h1>
      <h1 style={{ fontSize: "6rem", marginTop: "4rem" }}>404</h1>
      {/* <h1 style={{ fontSize: "2rem", marginTop: "4rem" }}>but we know which page exactly exists!</h1>
      <Link style={{ fontSize: "2rem"}} to='/shop'>SHOP</Link> */}
    </div>
    </>
  )
}

export default NotFound
