import styled from "styled-components"
import { Link as LinkRouter } from "react-router-dom"

export const NavLogoImg = styled.img`
  filter: invert(53%) sepia(25%) saturate(5717%) hue-rotate(159deg)
    brightness(92%) contrast(102%);
`
export const CartIsEmptyBtn = styled(LinkRouter)`
  background: black;
  // background: #796f6d;
  padding: 16px 32px;
  color: white;
  border: none;

  font-size: 1.2rem;

  border-radius: 4px;

  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #b9acac;
  }
`
