import { ElementContainer, ElementHead, TextBlockWrapper } from "./Elements"
import { BtnToShop, BtnWrap } from "../../AboutCenterpinsElements"
import FirstForm from "../../../../components/HomeHeroSection/FirstForm"

const AboutArmreel35 = () => {
  return (
    <>
      <ElementContainer id="about-Armreel-35">
        <ElementHead>Armreel-35</ElementHead>
        {/* <ElementH2>Why is Armreel 45 better than others?</ElementH2> */}
        <TextBlockWrapper>
          Armreel-35 is great for icefishing, spinners and for float fishing
          with tackle release, and other fishing methods that require a light,
          durable and reliable reel with a clicker, a rigid retainer and easy
          rotation of the spool.
          <br />
          <br />
          The reel parts are made aviation alloy AA2024 and high-strength steel.
          The spool and the body of the reel are machine-turned and anodised.
          <br />
          <br />
          Right—hand - winding clockwise.
          <br />
          Left—hand - winding counterclockwise.
          <br />
          <br />
          The compact, reliable, lightweight and durable reel is perfect for
          completing the "emergency kit" for tourists, travelers and people
          whose activities may be associated with survival in the wild. The reel
          is resistant to shocks, sand and snow, easy to disassemble for
          cleaning and lubrication during travel.
          <div style={{ padding: "1rem 1rem" }}>
            <b>Features:</b>
            <ul
            // style={{listStyleType: "none"}}
            >
              <li>Thick edge </li>
              <li>Shock-resistant</li>
              <li>Anti-reverse clicker</li>
              <li>Sand-resist</li>
            </ul>
          </div>
          <div style={{ padding: "1rem 1rem" }}>
            <b>SPEC:</b>
            <ul>
              <li>Mass: 3,5 oz. / 98 gramms</li>
              <li>Spool diam: 3.5 in. / 80 mm</li>
              <li>Spool width: 0.55 in. / 14 mm</li>
              <li>Capacity : 131 yds / 120 m (line 0.35 mm)</li>
              <li>Rotation: 2 x ABEC-5 marine-grade bearings</li>
              <li>Tractive effort: 35 lbs / 15 kg</li>
              <li>
                Warranty: The warranty against manufacturing defects is 5 years.
                Since 2009 of the master's work, only 0.1% of buyers have
                applied for warranty service.
              </li>
            </ul>
          </div>
        </TextBlockWrapper>
        <BtnWrap>
          <BtnToShop to="/shop/Armreel-35">BUY NOW</BtnToShop>
        </BtnWrap>
      </ElementContainer>
      <FirstForm />
    </>
  )
}

export default AboutArmreel35
