import styled from "styled-components"
import { Link as LinkRouter } from "react-router-dom"

export const Nav = styled.nav`
  background: ${({ scrollNav }) => {
    if (window.location.pathname === "/")
      return scrollNav ? "#161616;" : "transparent"
    else return "#161616;"
    // else return "linear-gradient(15deg,#073753 0%,#56a69d 100%);"
  }};
  height: ${({ scrollNav }) => {
    if (window.location.pathname === "/") return "80px"
    else return "60px"
  }};

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  // margin-top: -80px;
  // margin-top: ${() => (window.location.pathname === "/" ? "-80px" : "0px")};

  transition: 0.4s all ease;

  // transition-property: height;
  // transition-duration: 0.3s;
`

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;
  align-items: center;
`
export const NavbarContainerMob = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;
  align-items: center;
`

export const NavLogoWrap = styled(LinkRouter)`
  display: flex;
  text-decoration: none;
`
export const NavLogoWrapMob = styled(LinkRouter)`
  display: flex;
  text-decoration: none;
`

export const NavLogoText = styled.p`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 14px;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1.4px;

  @media screen and (max-width: 960px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavLogoImg = styled.img`
  height: ${() => {
    return window.location.pathname !== "/" ? "50px" : "68px"
  }};
  width: ${() => {
    return window.location.pathname !== "/" ? "50px" : "68px"
  }};
`
export const NavLogoImgLink = styled.a`
  height: ${() => {
    return window.location.pathname !== "/" ? "50px" : "68px"
  }};
  width: ${() => {
    return window.location.pathname !== "/" ? "50px" : "68px"
  }};
`
export const NavLogoImgMob = styled.img`
  height: ${() => {
    return window.location.pathname !== "/" ? "50px" : "78px"
  }};
  width: ${() => {
    return window.location.pathname !== "/" ? "50px" : "78px"
  }};
`

export const MobileIcon = styled.div`
  // margin-top: 4px;
  display: block;
  // position: absolute;
  // top: 0;
  // right: 0;
  // transform: translate(-1200%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
  color: #fff;
`

export const MyIcon = styled.div`
  margin-right: 6px;
  margin-top: 4px;

  animation: rotation 1s infinite alternate linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg) scale(1);
    }
    to {
      transform: rotate(16deg) scale(1.1);
    }
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  // margin-left: 100px;

  // position: relative;
  // left: 200px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavItem = styled.li`
  height: 80px;
`

export const NavLinks = styled(LinkRouter)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-size: 16px;

  &.active {
    border-bottom: 3px solid #01bf71;
  }
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
  color: #fff;
  cursor: pointer;
`
export const MyLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
  color: #fff;
  cursor: pointer;
`

export const NavBtnLink = styled.div`
  border-radius: 50px;
  background-color: #01bf11;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`

export const BasketIcon = styled(LinkRouter)`
  svg {
    color: "#fff";
  }

  margin-top: 4px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const BasketIconMobile = styled(LinkRouter)`
  display: block;

  font-size: 1.8rem;
  cursor: pointer;
  color: #fff;
`

export const Badge = styled.span`
  text-decoration: none;
  color: black;
  background-color: white;
  // background-color: #1a659e;
  padding: 2px 6px;
  font-size: 0.8rem;
  border-radius: 10px;
  margin: -3px;
  font-weight: bold;
`
export const NavbarLink = styled.a`
  margin-top: 6px;

`
