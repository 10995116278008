import {
  FirstBlockBtnWrapper,
  FirstBlockColumn1,
  FirstBlockColumn2,
  FirstBlockColumn2H1,
  FirstBlockColumn2P,
  FirstBlockSectionButton,
  FirstBlockWrap,
  Img,
  ImgWrap,
} from "./FirstBlockElements"
// import Image1 from "../../../images/homehero2.jpg"
import React, { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group"
import { ArrowForward, ArrowRight } from "../HeroElements"
// const nodeRef = useRef(null);

const SCROLL_STEP = 500

const FirstBlock = () => {
  const [isScroll, setIsScroll] = useState(false)
  const [showBlock, setShowBlock] = useState(false)
  const [hover, setHover] = useState(false)

  const onHover = () => setHover(!hover)
  const nodeRef = useRef(null)

  const handleScroll = () => {
    if (window.scrollY > SCROLL_STEP) setShowBlock(true)
    // console.log(showBlock)
    setShowBlock(true)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <FirstBlockWrap id="firstBlock">
      <FirstBlockColumn1 isScroll={isScroll}>
        <FirstBlockColumn2H1>The Future of Centerpin</FirstBlockColumn2H1>
        <FirstBlockColumn2P>
          Fishing with a good centerpin is the feeling of a fish with your
          fingertips. With our reels, we take the pleasure of fishing to next
          level and make it accessible even for beginners.
        </FirstBlockColumn2P>
        <FirstBlockBtnWrapper>
          <FirstBlockSectionButton
            to="centerpins"
            className="myButton"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            duration={500}
            exact="true"
            offset={-80}
            onClick={() => {
              try {
                window.gtag("event", "clickBtnLearnMore", {
                  event_name: "click",
                })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            Learn more about centerpins
            {/* LEARN MORE ABOUT CENTERPINS */}
            {hover ? <ArrowForward /> : <ArrowRight />}
          </FirstBlockSectionButton>
        </FirstBlockBtnWrapper>
      </FirstBlockColumn1>
      {/* <button
        onClick={() => {
          setShowBlock(true)
          console.log(showBlock)
        }}
      >
        CLICK
      </button> */}
      <CSSTransition
        in={showBlock}
        timeout={5000}
        // unmountOnExit
        classNames="alert"
        nodeRef={nodeRef}
      >
        <FirstBlockColumn2 ref={nodeRef} isScroll={isScroll} style={{}}>
          <ImgWrap>
            <Img
              src="https://ik.imagekit.io/armreels/home/homehero1.jpg?updatedAt=1685386458850"
              // srcSet="https://ik.imagekit.io/armreels/tr:w-400/homehero1.jpg?updatedAt=1685377253229 400w, 
              // https://ik.imagekit.io/armreels/tr:w-800/homehero1.jpg?updatedAt=1685377253229 800w"
              alt="centerpin armreels fish"
            />
            {/* <Img src={Image1} alt="centerpin armreels fish" /> */}
            {/* <VideoWrap>
            <video autoPlay loop muted src={Video} type="video/mp4"></video>
          </VideoWrap> */}
          </ImgWrap>
        </FirstBlockColumn2>
      </CSSTransition>
    </FirstBlockWrap>
  )
}

export default FirstBlock
