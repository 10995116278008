import styled from "styled-components"
import { Link as LinkScroll } from "react-scroll"

export const ElementContainer = styled.div`
  padding: 20px 20px;
  background: black;
  // background: white;
  color: white;
  margin: 1rem 0;
`
export const ElementHead = styled.h1`
  padding: 0px 10px;
  // background: white;
  margin: 20px 0px 20px 0px;
  font-size: 1.4rem;
  font-weight: bold;

  text-align: center;

  @media screen and (max-width: 768px) {
    margin: 10px 0px 20px 0px;
  }
`
export const Introduction = styled.div`
  // padding: 6px 10px;
  // background: white;
  margin: 0px 0px 20px 0px;
  // font-size: 1.2rem;
`

export const TextBlockWrapper = styled.div`
  line-height: 1.26rem;
  padding: 0;
  // background: white;
  margin: 0px 0px 20px 0px;
  // font-size: 1.2rem;
  // text-align: justify;
`
export const ElementH2 = styled.h2`
  font-size: 1.2rem;
  // padding: 10px 10px;
  margin: 30px 0px 20px 0px;
  text-align: center;
  // font-weight: normal;
`
export const ElementH3 = styled.h3`
  font-size: 1.1rem;
  // padding: 10px 10px;
  margin: 30px 0px 20px 0px;
  text-align: center;
  font-weight: normal;
`

export const ElementBigImg = styled.img`
  border-radius: 4px;  

  width: 640px;
  height: auto;
  margin: 4rem auto;
  display: flow-root;

  // padding: 1rem 0rem;

  @media screen and (max-width: 768px) {
    width: 420px;
    padding: 0rem;
  }

  @media screen and (max-width: 480px) {
    width: 260px;
    // padding: 1rem 0;
  }

  @media screen and (max-width: 320px) {
    width: 220px;
    // padding: 1rem 0;
  }
`

export const ElementVideo = styled.video`
  width: auto;
  max-height: 400px;
  float: left;
  border-radius: 4px;

  // margin: 0px 20px 20px 0px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ElementBigVideo = styled.video`
  width: 400px;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ElementImg = styled.img`
  width: 400px;
  max-height: 400px;
  float: left;
  border-radius: 4px;
  @media screen and (max-width: 768px) {
    width: 300px;
  }
  @media screen and (max-width: 440px) {
    // width: 240px;
  }
  @media screen and (max-width: 368px) {
    // width: 180px;
    width: 100%;
  }
`
export const StyledLink = styled(LinkScroll)`
  color: blue;
  cursor: pointer;
`
export const TextLink = ({ link, text }) => {
  return (
    <StyledLink
      to={link}
      smooth={true}
      duration={500}
      spy={true}
      exact="true"
      offset={-80}
    >
      {text}
    </StyledLink>
  )
}

export const ContentBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: grid;
  }
`
export const ContentColumn = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding: 1rem 1rem;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 1rem 0rem;
  }
`
export const TextColumn = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
`
export const TwoVideos = styled.div`
  // margin-top: auto;
  // margin-bottom: auto;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  // gap: 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 858px) {
    display: grid;
    justify-content: center;
    padding: 1rem 0rem;
    gap: 1rem;
  }
`
// export const BtnWrap = styled.div`
//   margin: 1rem 0;
//   display: flex;
//   justify-content: space-around;

//   @media screen and (max-width: 1000px) {
//     justify-content: center;
//     padding: 0 1rem;
//   }
// `
// export const BtnToShop = styled(LinkRouter)`
//   background: #796f6d;
//   padding: 8px 16px;
//   color: white;
//   border: none;
//   cursor: pointer;
//   text-decoration: none;

//   border-radius: 4px;

//   &:hover {
//     background-color: #b9acac;
//   }
//   @media screen and (max-width: 768px) {
//     font-size: 0.8rem;

//   }
// `
