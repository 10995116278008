import { useContext, useEffect } from "react"
import { MenuContext } from "../../context/menuContext"
import { items } from "../../items"
import {
  ItemCard,
  ItemH1,
  ItemImage,
  ItemImageWrap,
  ItemPriceWrap,
  ItemShortDescriptionWrap,
  ItemTextWrap,
  ShopContainer,
  ShopItemListWrap,
  ShopWrapper,
} from "./ShopElements"
import { animateScroll as scroll } from "react-scroll/modules"
import { ShopContext } from "../../context/shopContext"
import PageHeadWrapper from "../../components/Elements/PageHeadWrapper"
import {
  BreadCrumbs,
  BreadCrumbsLink,
  BreadCrumbsP,
} from "../../components/Elements/StyledElements"
import { Link } from "react-router-dom"
import SEO from "../../components/SEO"

const Shop = () => {
  const { togglePages } = useContext(MenuContext)
  const { setShowItem } = useContext(ShopContext)

  useEffect(() => {
    togglePages()
    scroll.scrollToTop()

    // const loadImage = imageName => {
    //   import(`./assets/${imageName}.jpg`).then(image => {
    //     this.setState({
    //       image
    //     });
    //   });
    // };
  }, [])
  return (
    <>
     <SEO
        description="Armreels | Shop | The perfect mechanism for centerpin fishing"
        title="Armreels | Shop"
        keywords="armreels centerpin fishing shop catalog"
        canonical="https://armreels.com/shop"
      />
    <ShopContainer id="shop" className="myShop">
      <ShopWrapper>
        <BreadCrumbs>
          <BreadCrumbsLink to="/">HOME</BreadCrumbsLink>
          <BreadCrumbsP>›</BreadCrumbsP>
          <BreadCrumbsLink style={{ cursor: "default" }}>SHOP</BreadCrumbsLink>
        </BreadCrumbs>
        {/* <H1Wrap className="H1Wrap">
          <h2>Shop</h2>
        </H1Wrap> */}
        <PageHeadWrapper text="Shop" />
        <ShopItemListWrap className="ShopItemListWrap">
          {items.map((item, index) => {
            return (
              <ItemCard key={index} className="ItemCard">
                {/* {item.onAmazon && (
                  <ViewLinkWrap>
                    <a href="https://a.co/d/fgF5j2j" target="_blank">
                      <FaAmazon
                        style={{
                          color: "black",
                          width: "1.4rem",
                          height: "1.4rem",
                          paddingTop: "4px",
                        }}
                      />
                    </a>
                  </ViewLinkWrap>
                )} */}
                <ItemImageWrap to={`/shop/${item.name}`}>
                  <ItemImage
                    src={item.currentSrc}
                    // src={require(`../../images/items/${item.name}/image1.png`)}
                    alt={item.fullname}
                    className="ItemImage"
                    onClick={() => {
                      setShowItem(item)
                    }}
                  />
                </ItemImageWrap>
                <ItemTextWrap>
                  <Link
                    to={`/shop/${item.name}`}
                    onClick={() => {
                      setShowItem(item)
                    }}
                    style={{textDecoration:"none"}}
                  >
                    <ItemH1>{item.shop_name}</ItemH1>
                  </Link>
                  {/* <ItemH2
                    style={{
                      textDecoration: "line-through",
                      textDecorationColor: "black",
                      textDecorationStyle: "solid",
                      textDecorationThickness: "auto",
                    }}
                  >
                    <i>{item.previous_price}</i>
                  </ItemH2> */}
                  <ItemPriceWrap>
                    <p>{item.current_price}</p>
                    {/* <p
                      style={{
                        textDecoration: "line-through",
                        textDecorationColor: "black",
                        textDecorationStyle: "solid",
                        textDecorationThickness: "auto",
                        fontSize: "0.9rem",
                        fontWeight: "500",
                        marginTop: "0.1rem",
                      }}
                    >
                      {item.previous_price}
                    </p> */}
                  </ItemPriceWrap>
                  <ItemShortDescriptionWrap style={{ minHeight: "40px" }}>
                    <p>{item.short_shop_description}</p>
                  </ItemShortDescriptionWrap>
                  {/* <ViewWrap style={{ minHeight: "50px" }}>
                    <ItemH3
                      to={`/shop/${item.name}`}
                      onClick={() => {
                        setShowItem(item)
                      }}
                    >
                      Fast view
                    </ItemH3> */}

                  {/* {item.onAmazon && (
                      <ViewLinkWrap>
                        <a href="https://a.co/d/fgF5j2j" target="_blank">
                          <FaAmazon
                            style={{
                              color: "black",
                              width: "1.4rem",
                              height: "1.4rem",
                              paddingTop: "4px"
                            }}
                          />
                        </a>
                      </ViewLinkWrap>
                    )} */}
                  {/* </ViewWrap> */}
                </ItemTextWrap>
              </ItemCard>
            )
          })}
        </ShopItemListWrap>
      </ShopWrapper>
    </ShopContainer>
    </>
  )
}

export default Shop
