import React, { useContext, useEffect } from "react"
import { MenuContext } from "../../context/menuContext"
import { ReturnsContainer, ReturnsH1, ReturnsP } from "./ReturnsElements"
import { animateScroll as scroll } from "react-scroll/modules"
import SEO from "../../components/SEO"

const ReturnsExchanges = () => {
  const { togglePages } = useContext(MenuContext)

  useEffect(() => {
    togglePages()
    scroll.scrollToTop()
    // setIsCheckOut(false)
  }, [])
  return (
    <>
      <SEO
        description="Armreels | Returns and exchanges | The perfect mechanism for centerpin fishing"
        title="Armreels | Returns and exchanges"
        keywords="armreels centerpin centre-pins fishing"
        canonical="https://armreels.com/returns-and-exchanges"
      />
    <ReturnsContainer>
      <div style={{ marginBottom: "2rem" }}>
        <ReturnsH1>WARRANTY AND RETURN POLICY</ReturnsH1>
        <ReturnsP>
          We put a lot of care, effort, and engineering into our reels. We’re
          proud of them, confident in them, and we sure you to be too. That’s
          why every reel we make is protected by one of the industry’s best
          warranties.
        </ReturnsP>
      </div>
      <div>
        <ReturnsH1>FIVE YEAR WARRANTY FOR MANUFACTURER DEFECTS</ReturnsH1>
        <ReturnsP>
          Since <b>2009</b> of the master's work, only <b>0.1%</b> of customers
          have applied for warranty service.
          <br />
          <br />
          Registration is not required to activate your warranty. If you do have
          any issues with a reel purchased direct from us please drop us a line
          <i>
            <b> sales@armreels.com </b>
          </i>
          and we’ll make you fishing again!
        </ReturnsP>
      </div>
      <div style={{ margin: "1rem 0" }}>
        <ReturnsH1>RETURNS</ReturnsH1>
        <ReturnsP>
          We offer easy returns for refund for up to <b>14</b> days from
          delivery date and exchanges for up to <b>60</b>-days from your
          purchase! Just make sure to hold your original packaging and keep
          your return in like new and unused condition.
          <br />
          <br />
          To start a return please send us an email with the original PF order
          number, the name and quantity of the items being returned and your
          most current return shipping address:
          <i>
            <b> sales@armreels.com </b>
          </i>
        </ReturnsP>
      </div>
    </ReturnsContainer>
    </>
  )
}

export default ReturnsExchanges
