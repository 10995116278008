import {
  // ThirdBlockComment,
  ThirdBlockImage,
  ThirdBlockImageWrap,
  ThirdBlockStar,
  ThirdBlockTextWrap,
  ThirdBlockWrap,
} from "./ThirdBlockElements"
import { Link } from "react-router-dom"

const Element = ({ imageSrc, imageSrcSet, alt, name, country, contact, short_desc, text, model }) => {
  return (
    <ThirdBlockWrap>
      {/* <Carousel/> */}
      <ThirdBlockImageWrap>
        <ThirdBlockImage src={imageSrc} srcSet={imageSrcSet} effect="blur" alt={alt} />
      </ThirdBlockImageWrap>
      <ThirdBlockTextWrap>
        <div style={{ display: "flex" }}>
          <h1>{name}</h1>
          <h3>{country}</h3>
        </div>
          <h5>{contact}</h5>
        <div style={{ display: "flex", gap: "0.2rem" }}>
          <ThirdBlockStar />
          <ThirdBlockStar />
          <ThirdBlockStar />
          <ThirdBlockStar />
          <ThirdBlockStar />
          <h2>{short_desc}</h2>
        </div>
        <p>{text}</p>
        <Link to={`/shop/Armreel-45`} style={{ textDecoration: "none" }}>
          {/* <h4>ARMREEL 45</h4> */}
          <h4>{model}</h4>
        </Link>
      </ThirdBlockTextWrap>
    </ThirdBlockWrap>
  )
}

export default Element
