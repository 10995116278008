import { items } from "../../../items"
import MinItemCard from "./MinItemCard"
import { SecondBlockContainer, SecondBlockH1, SecondBlockWrapper } from "./SecondBlockElements"

const SecondBlock = () => {
  return (
    <SecondBlockContainer>
      <SecondBlockH1>Armreels centerpins catalog</SecondBlockH1>
      <SecondBlockWrapper>
        {items.map((item, index) => {
          return <MinItemCard key={index} name={item.name} isTop={item.isTop} src={item.currentSrc}/>
        })}
      </SecondBlockWrapper>
    </SecondBlockContainer>
  )
}

export default SecondBlock
