// import "./Preloader.css"
import styled from "styled-components"
import icon from "../../../images/logo1.png"

const MyPreloadIcon = styled.img`
  color: white;
  width: auto;
  height: 100px;
  margin: auto;

  animation: rotating 4s linear infinite;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
const MyPreloadContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding: 1rem 1rem;

    height: auto;
    width: 300px;
    display: flex;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    padding: 1rem 0rem;
    width: 200px;

  }
`

const Preloader = () => {
  return (
    <MyPreloadContainer>
      {/* <svg
        className="pl"
        viewBox="0 0 200 200"
        width="200"
        height="200"
      >
        <defs>
          <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
            <stop offset="0%" />
            <stop offset="100%" />
          </linearGradient>
          <linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" />
            <stop offset="100%" />
          </linearGradient>
        </defs>
        <circle
          className="pl__ring"
          cx="100"
          cy="100"
          r="82"
          fill="none"
          stroke="url(#pl-grad1)"
          strokeWidth="36"
          strokeDasharray="0 257 1 257"
          strokeDashoffset="0.01"
          strokeLinecap="round"
          transform="rotate(-90,100,100)"
        />
        <line
          className="pl__ball"
          stroke="url(#pl-grad2)"
          x1="100"
          y1="18"
          x2="100.01"
          y2="182"
          strokeWidth="36"
          strokeDasharray="1 165"
          strokeLinecap="round"
        />
      </svg> */}
      <MyPreloadIcon src={icon} alt="armreels" />
    </MyPreloadContainer>
  )
}

export default Preloader
