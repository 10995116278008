import styled from "styled-components"
import { Link as LinkRouter } from "react-router-dom"

export const BreadCrumbs = styled.div`
  display: flex;
  margin-bottom: 40px;
  color: white;
`

export const BreadCrumbsLink = styled(LinkRouter)`
  text-decoration: none;
  font-size: 0.8rem;
  // color: black;
  color: white;

  margin-bottom: auto;
  margin-top: auto;

  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
  }
`
export const BreadCrumbsP = styled.p`
  padding: 0px 16px;
  font-size: 1.2rem;
  margin-top: -4px;
`

export const DownloadButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin: 2rem 0;

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 0.8rem;
  }
`

export const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: white;
  border-radius: 4px;
  padding: 0.8rem 1.6rem;
  gap: 1rem;
  a {
    color: black;
    text-decoration: none;
    font-size: 1rem;
    margin-top: auto;
    margin-bottom: auto;
  }

  @media screen and (max-width: 400px) {
    padding: 0.6rem 0.8rem;
    a {
      font-size: 0.9rem;
    }
  }
`
