import styled from "styled-components"
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md"
// import { Link } from "react-router-dom"
// import { Link } from "react-scroll"

export const HeroContainer = styled.div`
  margin-top: -80px;

  // background: #f1f1f1;
  display: flex;
  // justify-content: center;
  // align-items: center;
  padding: 0 30px;
  height: 100vh;
  // height: 800px;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);

    z-index: 2;
  }

  @media screen and (max-width: 1000px) {
    padding: 0 20px;
  }

  // @media screen and (max-width: 480px) {
  //   padding: 0 20px;
  // }
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  --o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`
export const ImgBg = styled.img`
  width: 100%;
  height: 100%;
  --o-object-fit: cover;
  object-fit: cover;
  background: #232a34;

  @media screen and (max-width: 768px) {
    // background-repeat: no-repeat;
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    // background-position: center;
  }
`

export const HeroContent = styled.div`
  float: left;
  max-width: 500px;

  margin-top: auto;
  margin-bottom: auto;
  margin-left: 4.4rem;

  z-index: 3;
  // max-width: 1200px;
  // position: absolute;
  padding: 8px 24px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  @media screen and (max-width: 1000px) {
    padding: 8px 14px;
    margin-left: 2.4rem;
  }
  @media screen and (max-width: 768px) {
    padding: 8px 14px;
    display: grid;

    margin-left: 0;
  }
`

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 58px;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    margin-top: 10rem;
    text-align: center;
  }
`
export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: left;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`
export const HeroBtnWrapper = styled.div`
  float: left;

  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 100;
  // @media screen and (max-width: 768px) {
  //   button {
  //     padding: 5px 10px;
  //   }
  // }
`
export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`
export const HeroSectionBtnWrap = styled.div`
margin-top: 2rem;
`


export const HeroSectionButton = styled.button`
  background: inherit;

  white-space: nowrap;
  padding: 12px 30px;
  color: yellow;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  border: 1px solid yellow;

  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
  }
  @media screen and (max-width: 768px) {
    padding: 8px 12px;

    font-size: 14px;
  }

  @media screen and (max-width: 300px) {
    padding: 8px 12px;
    white-space: break-spaces;

    font-size: 14px;
  }
`

export const HeroMainTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // min-height: 100vh;
  width: 100%;
  margin-top: 12rem;
`
export const HeroMainTextWrapper = styled.div`
  // width: 600px;

  h1 {
    width: 400px;

    margin: 0;
    letter-spacing: 0px;
    font-size: 3rem;
    color: white;

    @media screen and (max-width: 768px) {
      max-width: 300px;
      font-size: 2.4rem;
      // text-align: center;
    }
  
    @media screen and (max-width: 360px) {
      font-size: 2rem;
      width: 200px;

    }
  }
  
  p {
    font-size: 2rem;
    color: white;
    line-height: 2rem;
    position: relative;
    top: .8rem;
    opacity: 0;
    animation: .5s swift-up ease-out forwards, .5s fade-in ease-out forwards;
    animation-delay: .8s;

    @media screen and (max-width: 768px) {
      font-size: 1.6rem;
      // text-align: center;
    }
  
    @media screen and (max-width: 360px) {
      max-width: 200px;
      line-height: 1.6rem;
      font-size: 1.4rem;
    }
  }
  
  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }
  
  span {
    display: inline-block;
    overflow: hidden;
    position: relative;
    top: .8rem;
    animation: .3s swift-up ease-in-out forwards;
  }
  
  i {
    font-style: normal;
    position: relative;
    top: 4rem;
    animation: .5s swift-up ease-in-out forwards;
  }
  
  @keyframes swift-up {
    to {
      top: 0rem;
    }
  }
  
  span:nth-of-type(1) i {
    animation-delay: 0s;
  }
  
  span:nth-of-type(2) i {
    animation-delay: .1s;
  }
  
  span:nth-of-type(3) i {
    animation-delay: .2s;
  }
  
  span:nth-of-type(4) i {
    animation-delay: .3s;
  }
  
  span:nth-of-type(5) i {
    animation-delay: .4s;
  }
  
  span:nth-of-type(6) i {
    animation-delay: .5s;
  }
  
  span:nth-of-type(1) {
    animation-delay: 0s;
  }
  
  span:nth-of-type(2) {
    animation-delay: .1s;
  }
  
  span:nth-of-type(3) {
    animation-delay: .2s;
  }
  
  span:nth-of-type(4) {
    animation-delay: .3s;
  }
  
  span:nth-of-type(5) {
    animation-delay: .4s;
  }
  
  span:nth-of-type(6) {
    animation-delay: .5s;
  }
`

// export const HeroSectionButton = styled.button`

// `
