import { Link } from "react-router-dom"
import styled from "styled-components"
export const SecondBlockContainer = styled.div`
  background-color: #1a191a;
  height: auto;

  padding: 2rem 4rem;

  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
  }
`
export const SecondBlockWrapper = styled.div`
  margin: 0 auto;
  columns: 4;
  column-gap: 2rem;
  row-gap: 2rem;

  @media screen and (max-width: 1000px) {
    columns: 2;
  }
  @media screen and (max-width: 768px) {
    columns: 1;
  }
`
export const SecondBlockH1 = styled.h1`
  color: white;
  margin: 2rem 1rem;
  letter-spacing: 0.12rem;
  font-size: 2rem;

  @media screen and (max-width: 768px) {
    margin: 2rem 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
`
export const MenuItemWrap = styled.div`
  //   padding: 20px 0px;
`
export const MenuItemImgWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 240px;
    height: auto; 
    padding: 1rem 0rem;
  }

  @media screen and (max-width: 768px) {
    img {      
      width: 400px;
      padding: 1rem 2rem 0rem 2rem;
    }  
  }

  @media screen and (max-width: 480px) {
    img {      
      width: 240px;
      padding: 2rem 0 1rem 0;
    }
  }

  @media screen and (max-width: 320px) {
    img {      
      width: 200px;
      padding: 2rem 0 1rem 0;
    }
  }
`
export const MenuItemName = styled.div`
  position: relative;
  // top: -10rem;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: 1.6rem;

  @media screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`
export const MenuItemButton = styled(Link)`
  position: relative;
  // top: -9rem;
  margin: 0 0 1.4rem 0;
  white-space: nowrap;
  padding: 12px 30px;

  font-weight: bold;
  background: #ffe500;
  color: black;

  font-size: 16px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  border: 1px solid yellow;

  @media screen and (max-width: 768px) {
    padding: 8px 12px;
    svg {
      margin-top: 2px;
    }
    font-size: 14px;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    // background: ${({ primary }) => (primary ? "#fff" : "yellow")};
    // color: white;
  }
`

export const MenuItemHitWrap = styled.div`
  background: #ffe500;
  position: absolute;
  top: 2rem;
  left: 2rem;
  padding: 0.6rem 1.2rem;

  box-shadow: 0 2px 4px rgba(0, 0.4, 0, 0.4);

  animation: transform 2s infinite linear;

  @keyframes transform {
    from {
      scale: 0.98;
      opacity: 0.7;

    }
    to {
        scale: 1;
        opacity: 1;

    }
  }

  @media screen and (max-width: 768px) {
    top: 2rem;
    left: 2rem;
    padding: 0.8rem 1.8rem;


  }
  @media screen and (max-width: 516px) {
    top: 2rem;
    left: 2rem;
    padding: 0.4rem 0.8rem;
  }
  @media screen and (max-width: 340px) {
    top: 1rem;
    left: 1rem;
    padding: 0.2rem 0.4rem;
  }
`
export const MenuItemHitP = styled.p`
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.12rem;
`
