import {
  AboutNavContainer,
  AboutNavWrapper,
} from "./AboutNavElements"
import AboutNavItem from "./AboutNavItem"

const AboutNav = () => {
  return (
    <AboutNavContainer>
      <AboutNavWrapper>
        <div style={{}}>
          <AboutNavItem text="Armreel-45" link="about-Armreel-45" />
          <AboutNavItem text="Armreel-45-Е" link="about-Armreel-45-E"/>
          <AboutNavItem text="Armreel-35" link="about-Armreel-35"/>
          <AboutNavItem text="Armreel-25" link="about-Armreel-25"/>
        </div>
        {/* <div>
          <AboutNavItem text="Text1Text1Text1Text1Text1Text1Text1Text1" link="text_Armreel-45"/>
          <AboutNavItem text="Text2" link="text_Armreel-45"/>
          <AboutNavItem text="Text3" link="text_Armreel-45"/>
          <AboutNavItem text="Text4" link="text_Armreel-45"/>
        </div> */}
      </AboutNavWrapper>
    </AboutNavContainer>
  )
}

export default AboutNav
