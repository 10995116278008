import React, { useContext, useEffect } from "react"
import { MenuContext } from "../../context/menuContext"
import { PrivacyContainer, PrivacyH1, PrivacyH2, PrivacyH3, PrivacyP } from "./PrivacyElements"
import { animateScroll as scroll } from "react-scroll/modules"
import SEO from "../../components/SEO"

const Privacy = () => {
  const { togglePages } = useContext(MenuContext)

  useEffect(() => {
    togglePages()
    scroll.scrollToTop()
    // setIsCheckOut(false)
  }, [])
  return (
    <>
       <SEO
        description="Armreels | Privacy Policy | The perfect mechanism for centerpin fishing"
        title="Armreels | Privacy Policy"
        keywords="armreels centerpin centre-pins fishing"
        canonical="https://armreels.com/privacy-policy"
      />
    <PrivacyContainer>
      <PrivacyH1>PRIVACY POLICY</PrivacyH1>
      <PrivacyH2>ARMREELS PRIVACY POLICY</PrivacyH2>
      <PrivacyP>
        This privacy policy lets you know how Armreels ("Armreels Centerpins"
        or "we" or "us") collects and shares information on our website at
        www.armreels.com.<br/><br/>This policy also covers your choices regarding the
        sharing of your information.
      </PrivacyP>
      <PrivacyH3>Personal Information</PrivacyH3>
      <PrivacyP>
        We use the personal information you provide for purposes including, but
        not limited to: Fulfilling requests for products, services or
        information Tracking and confirming online orders Delivering products
        Providing customer service Offering new products and services Improving
        the effectiveness of our website, our marketing endeavors and our
        services and offers Conducting research and analysis Sending marketing
        communications Performing other business activities as needed, or as
        described elsewhere within this Policy.<br/><br/>We may also obtain additional
        personal and non-personal information from third party providers. If you
        provide us information about others, or if others give us your
        information, we may store that data and will only use that information
        for the specific reason it was provided. Examples include providing a
        friend's shipping address, e-mailing a weekly ad to a friend, or
        e-mailing friends a link to a "wish list".
      </PrivacyP>
      <PrivacyH3>Your Elections</PrivacyH3>
      <PrivacyP>
        At any time, you may elect to (a) stop receiving e-mail, direct mail,
        phone and mobile marketing communications or (b) update your personal
        information. We communicate with our customers through multiple channels
        and vehicles to keep you up-to-date on promotions, offers, customer
        experience content and new products or services.<br/><br/>Once you provide your
        personal information, we may contact you through e-mail or texts. We may
        continue to communicate with you through these channels unless you
        request to stop receiving these communications by opting out. Before
        sending you mobile marketing (e.g., text messages) we will require your
        express consent by requesting that you opt-in.
      </PrivacyP>
      <PrivacyH3>Uses of Information</PrivacyH3>
      <PrivacyP>
        Uses of Information We do not sell, rent or trade your individual
        personal information to third parties. We may share, sell or rent
        aggregated data with third parties for analysis and research purposes.
        This data does not contain name, address or other personally
        identifiable information (e.g., xx% of our visitors are 25 years old) to
        third parties. We use information about you to fulfill your requests,
        administer various programs, provide services, and for other business
        purposes.<br/><br/>From time to time, we may be required to share personal
        information in response to a valid court order, subpoena, government
        investigation, or as otherwise required by law. We also reserve the
        right to report to law enforcement agencies any activities that we, in
        good faith, believe to be unlawful. We may share certain personal
        information when we believe that such release is reasonably necessary to
        protect the rights, property and safety of others and ourselves. We may
        also transfer personal information in the event of a corporate sale,
        merger, acquisition, dissolution or similar event. Any personally
        identifiable information you submit on a bulletin board, blog, chat
        room, comment about a product, or any other public forum on this website
        can be read, collected, or used by other users of these forums, and
        could be used to send you unsolicited messages. We recommend that you do
        not provide your name, e-mail address or any other personal information.
        Please note that if you choose to do so, it is at your own risk and you
        accept full responsibility for those posts and any consequences that may
        arise from posting this information. At times we may partner with a
        third party to create a site that may look as if you are on a
        www.armreels.com page.<br/><br/>These sites may be co-branded or may have the look
        and feel of being on our site. However, the information collected on
        these pages may not be covered under our Privacy Policy. We will provide
        notice on these sites and post the privacy policy that is applicable to
        the information collected on these pages.
      </PrivacyP>
      <PrivacyH1>Security of Your Personal Information</PrivacyH1>
      <PrivacyP>
        Whether you are shopping online or in our stores, we use reasonable
        security measures to protect the confidentiality of personal information
        under our control and appropriately limit access to it, including Secure
        Sockets Layer (SSL) protection technology.<br/><br/>We also avoid storing your user passwords in
        plain text. Information that you provide us for credit card transactions
        is stored with external merchant processors. While we use the security
        measures described above along with others, we cannot ensure or warrant
        the security of any information you transmit to us by e-mail, and you do
        so at your own risk.
      </PrivacyP>
    </PrivacyContainer>
    </>
  )
}

export default Privacy
