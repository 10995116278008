import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarLogoWrap,
  SidebarLogoImg,
  SidebarLogoText,
  SidebarMenu2,
} from "./SidebarElements"
import { useContext } from "react"
import { MenuContext } from "../../context/menuContext"
import logo from "../../images/logo2.png"
import { FaAmazon } from "react-icons/fa"

const Sidebar = () => {
  const { toggleNavbar, isSidebarOpen } = useContext(MenuContext)
  return (
    <SidebarContainer
      isSidebarOpen={isSidebarOpen}
      onClick={toggleNavbar}
      className="SidebarContainer"
    >
      <Icon onClick={toggleNavbar}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarLogoWrap to="/">
          <SidebarLogoImg
            src={logo}
            alt="armreels centerpin fish"
            className="SidebarLogoImg"
          />
          <SidebarLogoText>ARMREELS</SidebarLogoText>
        </SidebarLogoWrap>
        <SidebarMenu>
          <SidebarLink
            to="centerpins"
            onClick={() => {
              toggleNavbar()

              try {
                window.gtag("event", "navBarToAboutCentrepins", {
                  event_name: "click",
                })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            About centerpins
          </SidebarLink>
          <SidebarLink
            to="gallery"
            onClick={() => {
              toggleNavbar()

              try {
                window.gtag("event", "navBarToGallery", {
                  event_name: "click",
                })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            Gallery
          </SidebarLink>
          <SidebarLink
            to="shop"
            onClick={() => {
              toggleNavbar()

              try {
                window.gtag("event", "navBarToShop", {
                  event_name: "click",
                })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            Shop
          </SidebarLink>
          <SidebarLink
            to="contact"
            onClick={() => {
              toggleNavbar()

              try {
                window.gtag("event", "navBarToShop", {
                  event_name: "click",
                })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            Contact us
          </SidebarLink>
        </SidebarMenu>
        <SidebarMenu2>
          <SidebarLink
            style={{ fontSize: "1.2rem" }}
            to="privacy-policy"
            onClick={() => {
              try {
                window.gtag("event", "ToPrivacyPolicy", {
                  event_name: "click",
                })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            Privacy policy
          </SidebarLink>
          <SidebarLink
            style={{ fontSize: "1.2rem" }}
            to="returns-and-exchanges"
            onClick={() => {
              try {
                window.gtag("event", "ToReturnsAndExchanges", {
                  event_name: "click",
                })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            Returns & Exchanges
          </SidebarLink>
        </SidebarMenu2>
        <a
          href="https://a.co/d/fgF5j2j"
          target="_blank"
          style={{ justifyContent: "center", display: "flex" }}
          onClick={() => {
            try {
              window.gtag("event", "ToAMFromSidebar", {
                event_name: "click",
              })
            } catch (error) {
              console.log(error)
            }
          }}
        >
          <FaAmazon style={{ color: "white", width: "2rem", height: "2rem" }} />
          
        </a>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
