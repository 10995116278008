import React from "react"
import {
  ContentBlock,
  ContentColumn,
  ElementBigImg,
  ElementContainer,
  ElementH2,
  ElementH3,
  ElementHead,
  TextBlockWrapper,
  TextColumn,
} from "./Elements"
import FirstForm from "../../../../components/HomeHeroSection/FirstForm"
import Video1Load from "../../../../videos/AboutCenterpins/Armreel-45/start_video_load.jpg"
import SpinningFishingLoad from "../../../../videos/AboutCenterpins/Armreel-45/spinning_fishing_load.jpg"
import FloatFishingLoad from "../../../../videos/AboutCenterpins/Armreel-45/float_fishing_load.jpg"
import VideoBearingAssembly1Load from "../../../../videos/AboutCenterpins/Armreel-45/bearing_assembly1_load.jpg"
import VideoBearingAssembly2Load from "../../../../videos/AboutCenterpins/Armreel-45/bearing_assembly2_load.jpg"
import VideoClickerLoad from "../../../../videos/AboutCenterpins/Armreel-45/clicker_load.jpg"
import VideoBearingAssembly3Load from "../../../../videos/AboutCenterpins/Armreel-45/bearing_assembly3_load.jpg"
import VideoCastingBrakesLoad from "../../../../videos/AboutCenterpins/Armreel-45/aerodynamic_brakes_load.jpg"
import VideoKnobLoad from "../../../../videos/AboutCenterpins/Armreel-45/knob_load.jpg"
import VideoPadLoad from "../../../../videos/AboutCenterpins/Armreel-45/pad_load.jpg"
// import Video1 from "../../../../videos/AboutCenterpins/Armreel-45/start_video.mp4"
// import AboutSpinningFishing from "../../../../videos/AboutCenterpins/Armreel-45/about_spinning_fishing.mp4"
// import FloatFishing from "../../../../videos/AboutCenterpins/Armreel-45/float_fishing.mp4"
// import VideoClicker from "../../../../videos/AboutCenterpins/Armreel-45/clicker.mp4"
// import VideoKnob from "../../../../videos/AboutCenterpins/Armreel-45/knob.mp4"
// import VideoPad from "../../../../videos/AboutCenterpins/Armreel-45/pad.mp4"
// import VideoBearingAssembly1 from "../../../../videos/AboutCenterpins/Armreel-45/bearing_assembly1.mp4"
// import VideoBearingAssembly2 from "../../../../videos/AboutCenterpins/Armreel-45/bearing_assembly2.mp4"
// import VideoBearingAssembly3 from "../../../../videos/AboutCenterpins/Armreel-45/bearing_assembly3.mp4"
// import VideoCastingBrakes from "../../../../videos/AboutCenterpins/Armreel-45/aerodynamic_brakes.mp4"
// import Image2 from "../../../../images/home/homehero1.jpg"
import { BtnToShop, BtnWrap } from "../../AboutCenterpinsElements"
import { AiOutlineCloudDownload } from "react-icons/ai"
import Preloader from "../../../../components/Elements/Preloader/Preloader"
import pdfL from "../../../../manuals/Armreel-45-Service-Manual-L-2023.pdf"
import pdfR from "../../../../manuals/Armreel-45-Service-Manual-R-2023.pdf"
import {
  DownloadButton,
  DownloadButtons,
} from "../../../../components/Elements/StyledElements"
const AboutLazyVideo = React.lazy(() => import("../../AboutLazyVideo"))

const AboutArmreel45 = () => {
  return (
    <>
    
    <ElementContainer id="about-Armreel-45">
      <ElementHead>Armreel-45</ElementHead>
      <ElementH2>Why is Armreel-45 better than others?</ElementH2>
      <TextBlockWrapper>
        Briefly, it allows to cast very light baits, expanding the scope of the
        centerpin fishing to previously unimaginable horizons. With proper
        skill, it allows you to cast baits and rigging weighing <b>1/9 oz.</b>
        and even
        <b> 1/14 oz.</b>
        <br />
        <br />
        How did this become possible? We managed to create a sufficiently
        strong, but with extremely low inertia spool, equipped with turbine
        blades that create a brake on it so that the spool gives the bait only
        the necessary amount of fishing line, preventing backlashes.
        <ContentBlock>
          <ContentColumn>
            {/* <ElementVideo
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={true}
              src={Video1}
              type="video/mp4"
            /> */}
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo 
              src="https://ik.imagekit.io/armreels/About/45/new_start_video.mp4?updatedAt=1688493369529"
              // src={Video1} 
              type="small" 
              srcLoad={Video1Load} />
            </React.Suspense>
          </ContentColumn>
          <TextColumn>
            It is very difficult to make a high-quality riveted spool, we
            succeeded, competitors at the moment cannot make such reels.
            <br />
            <br />
            The spool and the reel body are made of heat-treated alloy AA2024F
            (AlCuMg2), which has a very high strength, but does not break. The
            coatings on this alloy do not look as beautiful as those used in
            other reel, such as alloy 6061, but we choose strength between a
            catchy appearance and strength.
            <br />
            <br />
            In order to obtain an accurate, durable and low-inertia spool, it is
            necessary to head each rivet separately, and the spool assembled in
            this way is turned on a high-precision machine.
            <br />
            <br />
          </TextColumn>
        </ContentBlock>
        On the periphery of the spools of many centerpins, a thick edge is
        formed, due to which it is convenient to slow down when you fighting
        fish. The problem is that edge greatly increases the inertia of the
        spool, making difficult to cast baits.
        <br />
        <br />
        We solved the problem of braking the spool without increasing its
        inertia by using a patented brake pad. It works as follows: when pressed
        the edge, the spool bends elastically and comes into contact with the
        brake pad. <br />
        <br />
        Since the friction force of the spool on the pad is much higher than the
        friction force on the finger, a slight pressure on the spool can quickly
        and timely create and remove the braking force necessary for the
        delicate containment of fish. In this case, the based braking energy is
        released on the pad, and not on the finger.
        <br />
        <br />
        In addition, due to the high manufacturing accuracy, the ABEC 7 bearing
        assembly is resistant to sand and dirt, which no competitor can boast
        of.
      </TextBlockWrapper>
      <ElementH2>Fishing styles</ElementH2>
      <ElementH3>Float fishing</ElementH3>
      <TextBlockWrapper>
        <ContentBlock>
          <TextColumn>
            Armreel-45 is great for fishing in all ways and types of float
            tackle. The minimally inert, carefully balanced spool of the
            centerpin is set in motion even by the lightest float on the weakest
            current, provided that the fishing line does not stick to the rod
            and the rings. But the most important advantage of the Armreel-45 is
            that it allows you to cast very light tackle and catch those places
            that are inaccessible to ordinary, even the most expensive
            centerpins. The ease of rotation and the strength of the reel are
            such that it can be used to catch both small brook trout and huge
            salmon. The brake pad allows you to take out the fish powerfully and
            delicately.
          </TextColumn>
          <ContentColumn>
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo
                src="https://ik.imagekit.io/armreels/About/45/float_fishing.mp4?updatedAt=1685471369244"
                // src={FloatFishing}
                srcLoad={FloatFishingLoad}
                type="small"
              />
            </React.Suspense>
          </ContentColumn>
        </ContentBlock>
      </TextBlockWrapper>
      <ElementH3>Spinning fishing</ElementH3>
      <TextBlockWrapper>
        <ContentBlock>
          <ContentColumn>
            {/* <ElementVideo
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={true}
              src={AboutSpinningFishing}
              type="video/mp4"
            /> */}
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo
                src="https://ik.imagekit.io/armreels/About/45/spinning_fishing.mp4?updatedAt=1688494430722"
                // src={AboutSpinningFishing}
                srcLoad={SpinningFishingLoad}
                type="small"
              />
            </React.Suspense>
          </ContentColumn>
          <TextColumn>
            Due to the low inertia of the Armreel-45 spool, the centerpin can be
            used instead of baitcasters and spinning reels for casting
            artificial baits and catching with it by spinning.
            <br />
            <br />
            The spinning tackle with a centerpin has a phenomenally high
            sensorics, which allows you to get great pleasure from the feeling
            of the fish's grip and fighting.
            <br />
            <br />
            Сenterpin allows you to use very strong fishing lines, for example,
            fluorocarbon, and successfully catch in thickets of aquatic plants,
            in the crowns of flooded trees.
            <br />
            <br />
          </TextColumn>
        </ContentBlock>
        In the presence of a current with a centerpin, you can perform a
        "reverse" winding, in which the fishing line is not taken, but on the
        contrary, it is given to the bait, as a result of which the bait does
        not move to the angler, but away from him. Such a bait movement is
        characteristic of a weakened, wounded victim and represents an ideal
        prey for a predator. In addition, by giving the fishing line, you can
        catch those places where it is simply impossible to deliver the bait
        with another tackle, for example, under the branches of a fallen tree
        when often fish is founded.
      </TextBlockWrapper>
      <ElementH2>Design</ElementH2>
      <ElementBigImg
        alt="centerpins armreels design"
        src="https://ik.imagekit.io/armreels/home/homehero1.jpg?updatedAt=1685386458850"
        // src={Image2}
      ></ElementBigImg>
      <ElementH3>Tension knob</ElementH3>
      <TextBlockWrapper>
        <ContentBlock>
          <ContentColumn>
            {/* <ElementBigVideo
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={true}
              src={VideoKnob}
              type="video/mp4"
            /> */}
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo
                src="https://ik.imagekit.io/armreels/About/45/knob.mp4?updatedAt=1685471369231"
                // src={VideoKnob}
                srcLoad={VideoKnobLoad}
                type="big"
              />
            </React.Suspense>
          </ContentColumn>
          <TextColumn>
            The Armreel-45 centerpin is equipped with a friction mechanism in
            the form of a washer made of a special friction material pressed
            against a polished steel surface by a plate spring. The amount of
            braking force is adjusted by rotating the nut, which is located on
            the reel body. This friction brake is very useful for those who are
            beginning to master the casting of the centerpin, but sometimes it
            is also used by experienced anglers in order to make the rotation of
            the spool less easy.
          </TextColumn>
        </ContentBlock>
      </TextBlockWrapper>
      <ElementH3>Anti-reverse clicker</ElementH3>
      <TextBlockWrapper>
        <ContentBlock>
          <ContentColumn>
            {/* <ElementBigVideo
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={true}
              src={VideoClicker}
              type="video/mp4"
            /> */}
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo
                src="https://ik.imagekit.io/armreels/About/45/clicker.mp4?updatedAt=1685471369716"
                // src={VideoClicker}
                srcLoad={VideoClickerLoad}
                type="big"
              />
            </React.Suspense>
          </ContentColumn>
          <TextColumn>
            The ratchet is made in such a way that when the angler exhausts the
            fishing line, it rotates easily, and if the fish tries to take it,
            creates some effort. The ratchet mainly serves to fix the spool
            during pauses between fishing trips, but can also be used for
            insurance to drag the very large fish. We advise you to use the
            brake pad as much as possible when taking out fish, because it
            allows you to realize a much more subtle and precise fatigue of the
            fish and minimizes the number of its escapes.
          </TextColumn>
        </ContentBlock>
      </TextBlockWrapper>
      <ElementH3>Aerodynamic brake</ElementH3>
      <TextBlockWrapper>
        The aerodynamic brake provides the casting ability to the reel,
        preventing backlashes. You can cast baits from 1/14 oz. having a skill.
        <ContentBlock>
          <ContentColumn>
            {/* <ElementBigVideo
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={true}
              src={VideoCastingBrakes}
              type="video/mp4"
            /> */}
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo
                src="https://ik.imagekit.io/armreels/About/45/aerodynamic_brakes.mp4?updatedAt=1685471369776"
                // src={VideoCastingBrakes}
                srcLoad={VideoCastingBrakesLoad}
                type="big"
              />
            </React.Suspense>
          </ContentColumn>
          <TextColumn>
            The aerodynamic brake is made in the form of turbine blades located
            on the spool and holes of variable cross-section in the reel body.
            By increasing the area of the holes by turning the valve, the angler
            increases the consumption of air blown by the turbine and the force
            with which the spool is braked.
          </TextColumn>
        </ContentBlock>
        This brake creates the maximum braking force with at high RPM of the
        spool, and it does not affect the float drift speed at low RPM.
      </TextBlockWrapper>
      <ElementH3>Brake pad</ElementH3>
      <TextBlockWrapper>
        Armreel-45 is equipped with a unique device in terms of simplicity and
        efficiency, which has no analogues and is protected by a patent – a
        brake pad. The brake pad is made of special rubber in the form of a ring
        segment attached to the reel body from the side of the spool through the
        spacers regulating the gap.
        <ContentBlock>
          <ContentColumn>
            {/* <ElementBigVideo
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={true}
              src={VideoPad}
              type="video/mp4"
            /> */}
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo
                src="https://ik.imagekit.io/armreels/About/45/pad.mp4?updatedAt=1685471369071"
                // src={VideoPad}
                srcLoad={VideoPadLoad}
                type="big"
              />
            </React.Suspense>
          </ContentColumn>
          <TextColumn>
            Such a brake works as follows – when the angler presses his fingers
            on the spool, reflexively squeezing the brush, as if trying to
            gently hold the fish, the spool bends elastically and comes into
            contact with the brake pad. The friction force between the bap and
            the spool is many times higher than the friction force of the spool
            on the fingers (especially if the fingers are wet), therefore, by
            slight pressure on the spool, you can quickly and timely create and
            remove the braking force necessary for delicate fish containment.
          </TextColumn>
        </ContentBlock>
        In this case, the based braking energy is released on the pad, and not
        on the finger. It is fundamentally important that the fingers that
        control the recoil force of the fishing line simultaneously feel the
        jerks of the fish. The result is an extremely precise, subtle and
        comfortable fight. The fishing line can be given at any time with almost
        no effort, or, gradually increasing the braking to the required maximum,
        delicately tire the fish.
      </TextBlockWrapper>
      <ElementH3>Bearing assembly</ElementH3>
      <TextBlockWrapper>
        <ContentBlock>
          <ContentColumn>
            {/* <ElementVideo
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={true}
              src={VideoBearingAssembly1}
              type="video/mp4"
            /> */}
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo
                src="https://ik.imagekit.io/armreels/About/45/bearing_assembly1.mp4?updatedAt=1685471369520"
                // src={VideoBearingAssembly1}
                srcLoad={VideoBearingAssembly1Load}
                type="small"
              />
            </React.Suspense>
          </ContentColumn>
          <TextColumn>
            The bearing assembly has a special design - if the reel is not
            disassembled, then dust ingress into the bearings is excluded. If
            you accidentally dropped the reel into the sand or very dirty water,
            then, without disassembling it, rinse it thoroughly in clean water
            and make several casts and you can continue fishing. After working
            in salt water, the reel must be rinsed with fresh water.
          </TextColumn>
        </ContentBlock>
      </TextBlockWrapper>
      <TextBlockWrapper>
        <ContentBlock>
          <TextColumn>
            At the same time, oil can be added to the bearings without
            unscrewing any screws. To lubricate the front bearing, it is enough
            to remove the rubber plug on the front cover, and the rear one can
            be lubricated by simply removing the spool from the housing.
          </TextColumn>
          <ContentColumn>
            {/* <ElementVideo
              autoPlay
              loop
              muted
              playsInline
              webkit-playsinline={true}
              src={VideoBearingAssembly2}
              type="video/mp4"
            /> */}
            <React.Suspense fallback={<Preloader />}>
              <AboutLazyVideo
                // src={VideoBearingAssembly2}
                src="https://ik.imagekit.io/armreels/About/45/bearing_assembly2.mp4?updatedAt=1688494025346"
                srcLoad={VideoBearingAssembly2Load}
                type="small"
              />
            </React.Suspense>
          </ContentColumn>
        </ContentBlock>
      </TextBlockWrapper>
      <ContentBlock>
        <ContentColumn>
          {/* <ElementVideo
            autoPlay
            loop
            muted
            playsInline
            webkit-playsinline={true}
            src={VideoBearingAssembly3}
            type="video/mp4"
          /> */}
          <React.Suspense fallback={<Preloader />}>
            <AboutLazyVideo
              src="https://ik.imagekit.io/armreels/About/45/bearing_assembly3.mp4?updatedAt=1688494930415"
              // src={VideoBearingAssembly3}
              srcLoad={VideoBearingAssembly3Load}
              type="small"
            />
          </React.Suspense>
        </ContentColumn>
        <TextColumn>
          We produce variants RH (reel with your right) and LH (reel with tour
          left) Armreel-45 with a 0.7 inch spool width and Armreel-45E with an
          increased fishing line capacity with a 1.0 inch spool width.
        </TextColumn>
      </ContentBlock>
      <TextBlockWrapper>
        The Armreel-45 model is used for a variety of fishing styles, but its
        advantages are most fully revealed in floatfishing (Bologna, Nottingham)
        and spinning.
        <br />
        <br />
        The reel parts are made of anodised aviation alloy AA2024 and
        high-strength steel.
        <br />
        <br />
        <div style={{ padding: "0rem 1rem", marginBottom: "1rem" }}>
          <b>Features:</b>
          <ul>
            <li>Casting brakes</li>
            <li>Brake pad</li>
            <li>Tension knob</li>
            <li>Anti-reverse clicker</li>
            <li>Oil port</li>
            <li>Sand-resist assembly</li>
          </ul>
        </div>
        Allows to cast baits weighing from 1/14 oz. (with proper training).
        Allows to cast super-heavy baits.
        <br />
        <br />
        Designed to catch trophy fish. Reel has an exceptionally powerful
        thrust, the design has a huge margin of safety. The reel is resistant to
        sand, dirt, water and requires minimal maintenance.
        <br /> <br />
        Does not require the use of a special rod. It is possible to use
        multifilament lines with a strength of up to 40 lbs, which significantly
        reduces the likelihood of breakage and loss of baits. Oil port allows to
        lubricate bearings without disassembling the spool.
        <br />
        <br />
        <div style={{ padding: "0rem 1rem", marginBottom: "1rem" }}>
          <b>SPEC:</b>
          <ul>
            <li>Mass: 7,6 oz. / 215 gramms</li>
            <li>Spool diam: 4.5 in. / 114 mm</li>
            <li>Spool width: 0.7 in. / 18 mm</li>
            <li>Capacity: 142 yds/ 130 m (line 0.35 mm)</li>
            <li>Rotation: 2 x ABEC-7 marine-grade bearings</li>
            <li>Tractive effort: 25 lbs/15 kg</li>
            <li>
              Warranty: The warranty against manufacturing defects is 5 years.
              Since 2009 of the master's work, only 0.1% of buyers have
              applied for warranty service.
            </li>
          </ul>
        </div>
      </TextBlockWrapper>
      <DownloadButtons>
        <DownloadButton
          onClick={() => {
            try {
              window.gtag("event", "Download_AM-45-L_manual", {
                event_name: "click",
              })
            } catch (error) {
              console.log(error)
            }
          }}
        >
          <AiOutlineCloudDownload
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              width: "24px",
              height: "24px",
            }}
          />
          <a href={pdfL} download>
            Service manual L-Hand
          </a>
        </DownloadButton>
        <DownloadButton
          onClick={() => {
            try {
              window.gtag("event", "Download_AM-45-R_manual", {
                event_name: "click",
              })
            } catch (error) {
              console.log(error)
            }
          }}
        >
          <AiOutlineCloudDownload
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              width: "24px",
              height: "24px",
            }}
          />
          <a href={pdfR} download>
            Service manual R-Hand
          </a>
        </DownloadButton>
      </DownloadButtons>
      <BtnWrap>
        <BtnToShop to="/shop/Armreel-45">BUY NOW</BtnToShop>
      </BtnWrap>
      
    </ElementContainer>
    <FirstForm/>
    </>
  )
}

export default AboutArmreel45
