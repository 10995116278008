import {
  MenuItemButton,
  // MenuItemHitP,
  // MenuItemHitWrap,
  MenuItemImgWrap,
  MenuItemName,
  MenuItemWrap,
} from "./SecondBlockElements"
import { LazyLoadImage } from "react-lazy-load-image-component"

const MinItemCard = ({ name, isTop, src }) => {
  return (
    <MenuItemWrap>
      <MenuItemImgWrap>
        <div style={{ position: "relative" }}>
          <LazyLoadImage
            effect="blur"
            alt="armreels centre pin"
            src={src}
            // src={require(`../../../images/items/${name}/image1.png`)}
          />
          {/* {isTop && (
            <MenuItemHitWrap>
              <MenuItemHitP>BESTSELLER</MenuItemHitP>
            </MenuItemHitWrap>
          )} */}
        </div>
        <MenuItemName>{name}</MenuItemName>
        <MenuItemButton to={`/shop/${name}`} onClick={()=>{
            try {
                  window.gtag("event", "armreelHomeItemsView", {
                    event_name: "click", item: `${name}` 
                  })
                } catch (error) {
                  console.log(error)
                }
        }}>
          {/* View more */}
          VIEW MORE
          </MenuItemButton>
      </MenuItemImgWrap>
    </MenuItemWrap>
  )
}

export default MinItemCard
