import React, { useContext, useEffect } from "react"
import { MenuContext } from "../../context/menuContext"
import { animateScroll as scroll } from "react-scroll/modules"
import {
  AboutBreadCrumbs,
  AboutContainer,
  AboutHeadWrapper,
  AboutTextWrapper,
  AboutWrapper,
  Introduction,
} from "./AboutCenterpinsElements"
import AboutItems from "./AboutItems"
import AboutNav from "./AboutNav"
import {
  BreadCrumbsLink,
  BreadCrumbsP,
} from "../../components/Elements/StyledElements"

import SEO from "../../components/SEO"

const AboutCenterpins = () => {
  const { togglePages } = useContext(MenuContext)

  useEffect(() => {
    togglePages()
    // scroll.scrollToTop()
    scroll.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO
        description="Armreels | About centerpins | Welcome the world of eternal classic of centerpin fishing"
        title="Armreels | About centerpins | The perfect mechanism for centerpin fishing"
        keywords="about armreels centrepins 45 35 25 bait-casting air brake fishing"
        canonical="https://armreels.com/centerpins"
      />
      <AboutContainer id="centerpins">
        <AboutWrapper>
          <AboutBreadCrumbs style={{ color: "white" }}>
            <BreadCrumbsLink to="/" style={{ color: "white" }}>
              HOME
            </BreadCrumbsLink>
            <BreadCrumbsP>›</BreadCrumbsP>
            <BreadCrumbsLink style={{ cursor: "default", color: "white" }}>
              ABOUT CENTERPINS
            </BreadCrumbsLink>
          </AboutBreadCrumbs>
          <AboutHeadWrapper style={{ background: "black" }}>
            <h2 style={{ color: "white" }}>About centerpins</h2>
          </AboutHeadWrapper>
          <AboutTextWrapper>
            <Introduction>
              Centerpin was invented <i>three thousand</i> years ago, but
              anglers still use it, despite the existence and continuous
              improvement of more complex batcasting and spinning reels.
              <br />
              <br />
              The reasons for the phenomenal survivability of centerpin consist
              in a <b>successful combination</b> of practical and aesthetic
              properties.
              <br />
              <br />
              Similarly, folding, combined knives and all kinds of multitools
              could not displace a "simple" knife consisting of a blade, guard
              and handle.
              <br />
              <br />
              High-quality centerpin allows you to very accurately and subtly
              control the bait, use strong fishing lines, if there is a need for
              it. It is incredibly reliable and durable, can serve several
              generations of anglers.
              <br />
              <br />A modern civilized angler goes fishing not for food, but for
              the pleasure derived from the fishing process. Centerpin allows
              you to feel the fish with your fingertips, directly, without gears
              dulling sensations.
              <br />
              <br />
              In terms of aesthetics and pleasure, fishing with centerpin can be
              compared only with fly fishing. In other respects, it is not
              entirely correct to compare or contrast these fishing methods,
              since both of them are exceptionally good.
            </Introduction>
            <AboutNav />
            <AboutItems />
          </AboutTextWrapper>
        </AboutWrapper>
      </AboutContainer>
    </>
  )
}

export default AboutCenterpins
