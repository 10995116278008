import React, { useState } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import styled from "styled-components"

export const PPButtonContainer = styled.form`
  margin: 10px 20px 10px 0;
  @media screen and (max-width: 768px) {
    margin: 10px 0 0 0 
  }
`
const PPButton = ({ total, setIsPpok }) => {
  const [paidFor, setPaidFor] = useState(false)
  const [error, setError] = useState(null)
  const handleApproveData = (orderID) => {
    setPaidFor(true)
    setIsPpok(true)
  }

  if (paidFor)
    return (
      <div style={{ margin: "1rem" }}>
        <p style={{ color: "green", textAlign: "center" }}>
          Payment made successfully
        </p>
      </div>
    )
  if (error)
    return (
      <div style={{ margin: "1rem" }}>
        <p style={{ color: "brown", textAlign: "center" }}>
          An error occurred during payment, please inform us by e-mail{" "}
          <b>sales@armreels.com</b>
        </p>
      </div>
    )
  return (
    <PPButtonContainer>
      <PayPalScriptProvider
        options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}
      >
        <PayPalButtons
          style={{
            color: "gold",
            layout: "horizontal",
            height: 40,
            tagline: false,
            shape: "rect",
            label: "pay",
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Armreels centerpins",
                  amount: {
                    value: 1,
                    // value: total,
                    // currency: "USD",
                  },
                },
              ],
            })
          }}
          onApprove={async (data, actions) => {
            const order = await actions.order.capture()
            console.log("ORDER", order)

            handleApproveData(data.orderID)
          }}
          onCancel={() => {
            console.log("CANCEL")
          }}
          onClick={(data, actions) => {}}
          onError={(err) => {
            setError(err)
            console.log("ERROR", err)
          }}
        />
      </PayPalScriptProvider>
    </PPButtonContainer>
  )
}

export default PPButton
