import styled from "styled-components"

export const DeliveryWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  // background-color: white;
  color: white;

  @media screen and (max-width: 1000px) {
    font-size: 1rem;
    display: grid;
    // justify-content: space-between;
  }
  // @media screen and (max-width: 768px) {
  //    padding: 20px 20px;

  // }
`
export const FormWrap = styled.div`
  width: 60%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`
export const DeliveryInformation = styled.div`
  padding: 10px 20px 10px 0px;
  // padding: 10px 20px 10px 20px;
  @media screen and (max-width: 768px) {
    padding: 10px 0px;
  }
`
export const DeliveryTableWrap = styled.div`
  min-height: 100vh;
  width: 40%;
  border-left: 1px solid grey;
  padding-left: 20px;
  grid-gap: 20px;
  @media screen and (max-width: 1000px) {
    width: 100%;
    border: none;
    padding-left: 0px;
  }
`
export const DeliveryTable = styled.table`
  width: 100%;
  display: grid;
  margin-top: 20px;
`
export const TableRow = styled.tr`
  height: 60px;
`
export const TableItemName = styled.td`
  width: 60%;
`
export const TableItemQuantity = styled.td`
  width: 30%;
  text-align: center;
`
export const TableItemTotal = styled.td`
  width: 10%;
  text-align: center;
`
export const TotalWrap = styled.div`
  border-top: 1px solid grey;
  padding: 20px;
  text-align: center;
`
export const TotalH1 = styled.h1`
  font-size: 1.4rem;
`
export const DeliveryImage = styled.img`
  width: 100%;
  height: auto;

  @media screen and (max-width: 1000px) {
    margin-top: 1rem;
  }
`
export const DeliveryСongratulations = styled.div`
  display: grid;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 4rem;
  @media screen and (max-width: 1000px) {
    padding: 2rem 1rem;
  }
`
