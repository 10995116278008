import {
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroMainTextContainer,
  HeroMainTextWrapper,
  HeroSectionButton,
  ImgBg,
  VideoBg,
  ArrowForward,
  ArrowRight,
  HeroSectionBtnWrap,
} from "./HeroElements"
// import img1 from "../../images/homehero1.jpg"
// import bgImgMob from "../../images/bg_mob.jpg"
// import Video from "../../videos/bg_video.mp4"
import ServiceIcon from "../../images/service_icon.svg"
import DeliveryIcon from "../../images/delivery_icon.svg"
import FishingIcon from "../../images/fishing_icon2.svg"

import { useContext } from "react"
import FirstBlock from "./FirstBlock"
import SecondBlock from "./SecondBlock"
import { MenuContext } from "../../context/menuContext"
import ThirdBlock from "./ThirdBlock"
import { useState } from "react"
// import { useRef } from "react"
import FirstForm from "./FirstForm"
import { animateScroll as scroll } from "react-scroll/modules"
import styled from "styled-components"
import SEO from "../SEO"

const AdvantagesContainer = styled.div`
  padding: 4rem 2rem 1rem 2rem;
  background: black;
`
const AdvantagesWrapper = styled.div`
  background: #1a191a;
  // background: black;
  padding: 3rem 6rem;
  display: flex;
  justify-content: center;
  gap: 8rem;
  border-radius: 1.2rem;

  p {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 768px) {
    display: grid;
    gap: 4rem;
    padding: 3rem 2rem;

    // justify-content: center;
  }
`
const MyIcon = styled.img`
  color: white;
  width: 36px;
  height: 36px;

  // filter: invert(99%) sepia(1%) saturate(2184%) hue-rotate(74deg)
  //   brightness(117%) contrast(100%);

  filter: invert(87%) sepia(36%) saturate(1382%) hue-rotate(358deg)
    brightness(99%) contrast(107%);
`

const HomeHeroSection = () => {
  const { width } = useContext(MenuContext)
  const [hover, setHover] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isFormSending_1, setIsFormSending_1] = useState(false)
  const [isFormSending_2, setIsFormSending_2] = useState(false)

  const onHover = () => setHover(!hover)

  const firstFormOpen = () => {
    setIsFormOpen(!isFormOpen)

    if (!isFormOpen) scroll.scrollTo(window.innerHeight - 70)
  }

  const handleMinFormDelivery_1 = () => {
    console.log("handleMinFormDelivery_1")
    setIsFormSending_1(!isFormSending_1)
  }

  const handleMinFormDelivery_2 = () => {
    console.log("handleMinFormDelivery_2")
    setIsFormSending_2(!isFormSending_2)
  }

  return (
    <>
      <SEO
        description="Armreels | Welcome to the world of eternal classic of centerpin fishing, where the main tool is your skill, complemented by an impeccable mechanism."
        title="Armreels Centerpins | The perfect mechanism for centerpin fishing"
        keywords=" centerpin fishing armreels centrepins bait-casting air brake centrepins centre-pins"
        canonical="https://armreels.com/"
      />
      <HeroContainer className="HeroContainer">
        <HeroBg className="HeroBg">
          {/* <VideoBg autoPlay loop muted src={Video} type="video/mp4" /> */}
          {/* {width < 600 ? <ImgBg src={bgImgMob} /> : <ImgBg src={bgImg} />} */}
          {width <= 768 ? (
            <ImgBg
              src="https://ik.imagekit.io/armreels/home/tr:w-800/bg_mob.jpg?updatedAt=1685386457366"
              alt="armreels centerpin fishing air brake"
            />
          ) : (
            // <ImgBg src={bgImgMob} alt="armreels centerpin fishing air brake" />
            <VideoBg autoPlay loop muted 
            src="https://ik.imagekit.io/armreels/home/bg_video.mp4?updatedAt=1685791327854"
            // src={Video} 
            type="video/mp4" />
          )}
        </HeroBg>
        <HeroContent className="HeroContent">
          <HeroMainTextContainer>
            <HeroMainTextWrapper>
              <h1>
                <span>
                  <i>Bait</i>
                </span>
                <span>
                  <i>-casting</i>
                </span>
                <span>
                  <i>centerpin</i>
                </span>
              </h1>
              <p>
                {/* Welcome to the world of eternal classic of centerpin fishing, where
            the main tool is your skill, complemented by an impeccable
            mechanism. */}
                {/* Eternal classic of centerpin fishing */}
                {/* Upgrade your fishing gear with our advanced fishing reels */}
                {/* Upgrade your fishing gear with our advanced centerpin reels */}
                Upgrade your fishing gear
              </p>
            </HeroMainTextWrapper>
          </HeroMainTextContainer>
          <HeroSectionBtnWrap>
            <HeroSectionButton
              // to="firstForm"
              // className="myButton"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              duration={10000}
              exact="true"
              // offset={-80}
              onClick={firstFormOpen}
            >
              ORDER NOW
              {hover ? <ArrowForward /> : <ArrowRight />}
            </HeroSectionButton>
          </HeroSectionBtnWrap>
        </HeroContent>
      </HeroContainer>
      {isFormOpen && (
        <FirstForm
          handleMinFormDelivery={handleMinFormDelivery_1}
          isFormSending={isFormSending_1}
        />
      )}
      <AdvantagesContainer>
        <AdvantagesWrapper>
          <div style={{ display: "flex" }}>
            <MyIcon
              alt="armreels centerpins service"
              src={FishingIcon}
            ></MyIcon>
            <div style={{ margin: "auto 0.8rem" }}>
              <p style={{ fontSize: "1.1rem", color: "white" }}>
              Unique technologies
              </p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <MyIcon
              alt="armreels centerpins delivery"
              src={DeliveryIcon}
            ></MyIcon>
            <div style={{ margin: "auto 0.8rem" }}>
              <p style={{ fontSize: "1.1rem", color: "white" }}>
                Worldwide shipping
              </p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <MyIcon
              alt="armreels centerpins fishing tech"
              src={ServiceIcon}
            ></MyIcon>
            <div style={{ margin: "auto 0.8rem" }}>
              <p style={{ fontSize: "1.1rem", color: "white" }}>
              5 years warranty
              </p>
            </div>
          </div>
        </AdvantagesWrapper>
      </AdvantagesContainer>
      {/* <FirstBlock img={img1} /> */}
      <FirstBlock />
      <SecondBlock />
      <ThirdBlock />
      <FirstForm
        handleMinFormDelivery={handleMinFormDelivery_2}
        isFormSending={isFormSending_2}
      />
    </>
  )
}

export default HomeHeroSection
